import styled from "styled-components";

import { INDENT_BETWEEN_PAGES } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";

export const Container = styled.div`
  display: flex;
  padding: ${INDENT_BETWEEN_PAGES}px;
  background-color: ${colors.bg};
  gap: 80px;
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: start;
  min-width: 400px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const ImageWrapper = styled.div`
  margin: 0 auto;
`;

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NumberWrapper = styled.div`
  display: flex;
  width: 184px;
  min-width: 184px;
  justify-content: center;
`;

export const AdviceWrapper = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`;

export const Advice = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AdviceTitle = styled.div`
  font-family: ${fontFamily.redHat.bold};
  font-size: 22px;
  line-height: 121%;
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 0.22px;
`;

export const AdviceText = styled.div`
  font-family: ${fontFamily.redHat.regular};
  font-size: 17px;
  line-height: 140%;
  letter-spacing: 0.17px;
`;

export const StyledUl = styled.ul`
  li:nth-child(2),
  li:nth-child(3) {
    padding: ${INDENT_BETWEEN_PAGES}px 0;
    border-bottom: 1px solid ${colors.gray[200]};
  }
  li:nth-child(1) {
    padding-bottom: ${INDENT_BETWEEN_PAGES}px;
    border-bottom: 1px solid ${colors.gray[200]};
  }
  li:nth-child(4) {
    padding-top: ${INDENT_BETWEEN_PAGES}px;
  }
`;
