import images from "../../assets/images";

export const cards = [
  {
    before: images.Before1,
    after: images.After1,
    altBefore: "Before1.pic",
    altAfter: "After1.pic",
    vertical: true,
  },
  {
    before: images.Before2,
    after: images.After2,
    altBefore: "Before2.pic",
    altAfter: "After2.pic",
    vertical: true,
  },
  {
    before: images.Before3,
    after: images.After3,
    altBefore: "Before3.pic",
    altAfter: "After3.pic",
    vertical: true,
  },
  {
    before: images.Before4,
    after: images.After4,
    altBefore: "Before4.pic",
    altAfter: "After4.pic",
    vertical: true,
  },
  {
    before: images.Before5,
    after: images.After5,
    altBefore: "Before5.pic",
    altAfter: "After5.pic",
    vertical: false,
  },
  {
    before: images.Before6,
    after: images.After6,
    altBefore: "Before6.pic",
    altAfter: "After6.pic",
    vertical: true,
  },
  {
    before: images.Before7,
    after: images.After7,
    altBefore: "Before7.pic",
    altAfter: "After7.pic",
    vertical: true,
  },
  {
    before: images.Before8,
    after: images.After8,
    altBefore: "Before8.pic",
    altAfter: "After8.pic",
    vertical: true,
  },
  {
    before: images.Before9,
    after: images.After9,
    altBefore: "Before9.pic",
    altAfter: "After9.pic",
    vertical: true,
  },
  {
    before: images.Before10,
    after: images.After10,
    altBefore: "Before10.pic",
    altAfter: "After10.pic",
    vertical: false,
  },
  {
    before: images.Before11,
    after: images.After11,
    altBefore: "Before11.pic",
    altAfter: "After11.pic",
    vertical: true,
  },
  {
    before: images.Before12,
    after: images.After12,
    altBefore: "Before12.pic",
    altAfter: "After12.pic",
    vertical: true,
  },
  {
    before: images.Before13,
    after: images.After13,
    altBefore: "Before13.pic",
    altAfter: "After13.pic",
    vertical: true,
  },
  {
    before: images.Before14,
    after: images.After14,
    altBefore: "Before14.pic",
    altAfter: "After14.pic",
    vertical: true,
  },
  {
    before: images.Before15,
    after: images.After15,
    altBefore: "Before15.pic",
    altAfter: "After15.pic",
    vertical: false,
  },
];
