import * as Yup from "yup";

export const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Name can only contain Latin letters")
    .required("This field is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("This field is required"),
}).required();
