import React from "react";

import {
  PolicyItemWrapper,
  PolicySubtitle,
  PolicyText,
  PolicyTitle,
  StyledLink,
  StyledList,
} from "../styled";

export const ProcessingPersonalData = () => {
  return (
    <PolicyItemWrapper id="processing personal data">
      <PolicyTitle>
        Detailed Information on the Processing of Your Personal Data
      </PolicyTitle>
      <PolicyText>
        The Service Providers We use may have access to Your Personal Data.
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies
      </PolicyText>
      <PolicySubtitle>Analytics</PolicySubtitle>
      <PolicyText>
        We may use third-party Service providers to monitor and analyze the use
        of our Service.
      </PolicyText>
      <PolicyText>
        <StyledList>
          <li>
            <b>Google Analytics</b>
            <br />
            Google Analytics Google Analytics is a web analytics service offered
            by Google that tracks and reports website traffic. Google uses the
            data collected to track and monitor the use of our Service. This
            data is shared with other Google services. Google may use the
            collected data to contextualize and personalize the ads of its own
            advertising network. You may opt-out of certain Google Analytics
            features through your mobile device settings, such as your device
            advertising settings or by following the instructions provided by
            Google in their Privacy Policy:
            <StyledLink
              href="https://policies.google.com/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </StyledLink>
            For more information on the privacy practices of Google, please
            visit the Google Privacy & Terms web page:
            <StyledLink
              href="https://policies.google.com/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </StyledLink>
          </li>
          <br />
          <li>
            <b>Firebase</b>
            <br />
            Firebase is an analytics service provided by Google Inc. You may
            opt-out of certain Firebase features through your mobile device
            settings, such as your device advertising settings or by following
            the instructions provided by Google in their Privacy Policy:
            <StyledLink
              href="https://policies.google.com/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </StyledLink>
            We also encourage you to review the Google's policy for safeguarding
            your data:
            <StyledLink
              href="https://support.google.com/analytics/answer/6004245"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://support.google.com/analytics/answer/6004245
            </StyledLink>
            For more information on what type of information Firebase collects,
            please visit the How Google uses data when you use our partners'
            sites or apps webpage:
            <StyledLink
              href="https://policies.google.com/technologies/partner-sites"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/technologies/partner-sites
            </StyledLink>
          </li>
        </StyledList>
      </PolicyText>

      <PolicySubtitle>Email Marketing</PolicySubtitle>
      <PolicyText>
        We may use Your Personal Data to contact You with newsletters, marketing
        or promotional materials and other information that may be of interest
        to You. You may opt-out of receiving any, or all, of these
        communications from Us by following the unsubscribe link or instructions
        provided in any email We send or by contacting Us. We may use Email
        Marketing Service Providers to manage and send emails to You.
        <br />
        <br />
        <b>Fluent CRM</b> <br />
        Their Privacy Policy can be viewed at
        <StyledLink
          href="https://wpmanageninja.com/privacy/"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://wpmanageninja.com/privacy/
        </StyledLink>
      </PolicyText>

      <PolicySubtitle>Payments</PolicySubtitle>
      <PolicyText>
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors). We will not store or collect Your payment card
        details. That information is provided directly to Our third-party
        payment processors whose use of Your personal information is governed by
        their Privacy Policy. These payment processors adhere to the standards
        set by PCI-DSS as managed by the PCI Security Standards Council, which
        is a joint effort of brands like Visa, Mastercard, American Express and
        Discover. PCI-DSS requirements help ensure the secure handling of
        payment information.
        <br />
        <br />
        <b>Apple Store In-App Payments</b> <br />
        Their Privacy Policy can be viewed at
        <StyledLink
          href="https://www.apple.com/legal/privacy/enww/"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.apple.com/legal/privacy/enww/
        </StyledLink>
        <br />
        <br />
        <b>Google Play In-App Payments</b> <br />
        Privacy Policy can be viewed at
        <StyledLink
          href="https://www.google.com/policies/privacy/"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.google.com/policies/privacy/
        </StyledLink>
        <br />
        <br />
        <b>Stripe</b> <br />
        Their Privacy Policy can be viewed at
        <StyledLink
          href="https://stripe.com/us/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://stripe.com/us/privacy
        </StyledLink>
        <br />
        <br />
        <b>Shopify</b> <br />
        Their Privacy Policy can be viewed at
        <StyledLink
          href="https://www.shopify.com/legal/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.shopify.com/legal/privacy
        </StyledLink>
        <br />
        <br />
        <b>PayPal</b> <br />
        Their Privacy Policy can be viewed at
        <StyledLink
          href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.paypal.com/webapps/mpp/ua/privacy-full
        </StyledLink>
        <br />
        <br />
        <b>Braintree</b> <br />
        Their Privacy Policy can be viewed at
        <StyledLink
          href="https://www.braintreepayments.com/legal/braintree-privacy-policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.braintreepayments.com/legal/braintree-privacy-policy
        </StyledLink>
      </PolicyText>

      <PolicySubtitle>Behavioral Remarketing</PolicySubtitle>
      <PolicyText>
        The Company uses remarketing services to advertise to You after You
        accessed or visited our Service. We and Our third-party vendors use
        cookies and non-cookie technologies to help Us recognize Your Device and
        understand how You use our Service so that We can improve our Service to
        reflect Your interests and serve You advertisements that are likely to
        be of more interest to You. These third-party vendors collect, store,
        use, process and transfer information about Your activity on Our Service
        in accordance with their Privacy Policies and to enable Us to:
        <StyledList>
          <li>
            Measure and analyze traffic and browsing activity on Our Service
          </li>
          <li>
            Show advertisements for our products and/or services to You on
            third-party websites or apps
          </li>
          <li>
            Measure and analyze the performance of Our advertising campaigns
          </li>
        </StyledList>
        <br />
        Some of these third-party vendors may use non-cookie technologies that
        may not be impacted by browser settings that block cookies. Your browser
        may not permit You to block such technologies. You can use the following
        third-party tools to decline the collection and use of information for
        the purpose of serving You interest-based advertising:
        <StyledList>
          <li>
            The NAI's opt-out platform:
            <StyledLink
              href="http://www.networkadvertising.org/choices/"
              rel="noopener noreferrer"
              target="_blank"
            >
              http://www.networkadvertising.org/choices/
            </StyledLink>
          </li>
          <li>
            The EDAA's opt-out platform
            <StyledLink
              href="http://www.youronlinechoices.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              http://www.youronlinechoices.com/
            </StyledLink>
          </li>
          <li>
            DAA's opt-out platform:
            <StyledLink
              href="http://optout.aboutads.info/?c=2&lang=EN"
              rel="noopener noreferrer"
              target="_blank"
            >
              http://optout.aboutads.info/?c=2&lang=EN
            </StyledLink>
          </li>
        </StyledList>
        <br />
        You may opt-out of all personalized advertising by enabling privacy
        features on Your mobile device such as Limit Ad Tracking (iOS) and Opt
        Out of Ads Personalization (Android). See Your mobile device Help system
        for more information. We may share information, such as hashed email
        addresses (if available) or other online identifiers collected on Our
        Service with these third-party vendors. This allows Our thirdparty
        vendors to recognize and deliver You ads across devices and browsers. To
        read more about the technologies used by these third-party vendors and
        their cross-device capabilities please refer to the Privacy Policy of
        each vendor listed below. The third-party vendors We use are:
        <br />
        <br />
        <b>Google Ads (AdWords)</b>
        <br />
        Google Ads (AdWords) remarketing service is provided by Google Inc. You
        can opt-out of Google Analytics for Display Advertising and customize
        the Google Display Network ads by visiting the Google Ads Settings page:
        <StyledLink
          href="http://www.google.com/settings/ads"
          rel="noopener noreferrer"
          target="_blank"
        >
          http:// www.google.com/settings/ads
        </StyledLink>
        Google also recommends installing the Google Analytics Opt-out Browser
        Add-on -
        <StyledLink
          href="https://tools.google.com/dlpage/gaoptout"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://tools.google.com/dlpage/gaoptout
        </StyledLink>
        - for your web browser. Google Analytics Opt-out Browser Add-on provides
        visitors with the ability to prevent their data from being collected and
        used by Google Analytics. For more information on the privacy practices
        of Google, please visit the Google Privacy & Terms web page:
        <StyledLink
          href="https://policies.google.com/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://policies.google.com/privacy
        </StyledLink>
        <br />
        <br />
        <b>Facebook</b>
        <br />
        Facebook remarketing service is provided by Facebook Inc. You can learn
        more about interest-based advertising from Facebook by visiting this
        page:
        <StyledLink
          href="https://www.facebook.com/help/516147308587266"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/help/516147308587266
        </StyledLink>
        To opt-out from Facebook's interest-based ads, follow these instructions
        from Facebook:
        <StyledLink
          href="https://www.facebook.com/help/568137493302217"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/help/568137493302217
        </StyledLink>
        Facebook adheres to the Self-Regulatory Principles for Online
        Behavioural Advertising established by the Digital Advertising Alliance.
        You can also opt-out from Facebook and other participating companies
        through the Digital Advertising Alliance in the USA
        <StyledLink
          href="http://www.aboutads.info/choices/"
          rel="noopener noreferrer"
          target="_blank"
        >
          http://www.aboutads.info/choices/
        </StyledLink>
        , the Digital Advertising Alliance of Canada in Canada
        <StyledLink
          href="http://youradchoices.ca/ "
          rel="noopener noreferrer"
          target="_blank"
        >
          http://youradchoices.ca/
        </StyledLink>
        or the European Interactive Digital Advertising Alliance in Europe
        <StyledLink
          href="http://www.youronlinechoices.eu/"
          rel="noopener noreferrer"
          target="_blank"
        >
          http://www.youronlinechoices.eu/
        </StyledLink>
        , or opt-out using your mobile device settings. For more information on
        the privacy practices of Facebook, please visit Facebook's Data Policy:
        <StyledLink
          href="https://www.facebook.com/privacy/explanation"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/privacy/explanation
        </StyledLink>
      </PolicyText>
    </PolicyItemWrapper>
  );
};
