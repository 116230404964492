import React from "react";
import { EMAIL } from "../../../lib/constants";

import {
  PolicyItemWrapper,
  PolicySubtitle,
  PolicyText,
  PolicyTitle,
  StyledLink,
  StyledList,
} from "../styled";

export const CCPAPrivacyNotice = () => {
  return (
    <PolicyItemWrapper id="ccpa privacy notice">
      <PolicyTitle>CCPA/CPRA Privacy Notice </PolicyTitle>
      <PolicyText>
        This privacy notice section for California residents supplements the
        information contained in Our Privacy Policy and it applies solely to all
        visitors, users, and others who reside in the State of California.
      </PolicyText>
      <PolicySubtitle>
        Categories of Personal Information Collected
      </PolicySubtitle>
      <PolicyText>
        We collect information that identifies, relates to, describes,
        references, is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with a particular Consumer or Device.
        The following is a list of categories of personal information which we
        may collect or may have been collected from California residents within
        the last twelve (12) months. Please note that the categories and
        examples provided in the list below are those defined in the CCPA/CPRA.
        This does not mean that all examples of that category of personal
        information were in fact collected by Us, but reflects our good faith
        belief to the best of Our knowledge that some of that information from
        the applicable category may be and may have been collected. For example,
        certain categories of personal information would only be collected if
        You provided such personal information directly to Us.
      </PolicyText>
      <PolicyText>
        <b>Category A: Identifiers.</b>
        <br />
        Examples: A real name, alias, postal address, unique personal
        identifier, online identifier, Internet Protocol address, email address,
        account name, driver's license number, passport number, or other similar
        identifiers. <br /> Collected: Yes.
      </PolicyText>
      <PolicyText>
        <b>
          CCategory B: Personal information categories listed in the California
          Customer Records statute (Cal. Civ. Code § 1798.80(e)).
        </b>
        <br />
        Examples: A name, signature, Social Security number, physical
        characteristics or description, address, telephone number, passport
        number, driver's license or state identification card number, insurance
        policy number, education, employment, employment history, bank account
        number, credit card number, debit card number, or any other financial
        information, medical information, or health insurance information. Some
        personal information included in this category may overlap with other
        categories. <br /> Collected: Yes.
      </PolicyText>
      <PolicyText>
        <b>
          Category C: Protected classification characteristics under California
          or federal law.
        </b>
        <br />
        Examples: Age (40 years or older), race, color, ancestry, national
        origin, citizenship, religion or creed, marital status, medical
        condition, physical or mental disability, sex (including gender, gender
        identity, gender expression, pregnancy or childbirth and related medical
        conditions), sexual orientation, veteran or military status, genetic
        information (including familial genetic information).
      </PolicyText>
      <PolicyText>
        <b>Category D: Commercial information.</b>
        <br />
        Examples: Records and history of products or services purchased or
        considered. <br /> Collected: Yes.
      </PolicyText>
      <PolicyText>
        <b>Category E: Biometric information.</b>
        <br />
        Examples: Genetic, physiological, behavioral, and biological
        characteristics, or activity patterns used to extract a template or
        other identifier or identifying information, such as, fingerprints,
        faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
        other physical patterns, and sleep, health, or exercise data. <br />
        Collected: No.
      </PolicyText>
      <PolicyText>
        <b> Category F: Internet or other similar network activity.</b>
        <br />
        Examples: Interaction with our Service or advertisement.
        <br />
        Collected: Yes
      </PolicyText>
      <PolicyText>
        <b> Category G: Geolocation data.</b>
        <br />
        Examples: Approximate physical location.
        <br />
        Collected: Yes
      </PolicyText>
      <PolicyText>
        <b> Category H: Sensory data.</b>
        <br />
        Examples: Audio, electronic, visual, thermal, olfactory, or similar
        information. Collected: No. Category I: Professional or
        employment-related information. Examples: Current or past job history or
        performance evaluations.
        <br />
        Collected: No.
      </PolicyText>
      <PolicyText>
        <b>
          Category J: Non-public education information (per the Family
          Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R.
          Part 99)).
        </b>
        <br />
        Examples: Education records directly related to a student maintained by
        an educational institution or party acting on its behalf, such as
        grades, transcripts, class lists, student schedules, student
        identification codes, student financial information, or student
        disciplinary records.
        <br />
        Collected: No.
      </PolicyText>
      <PolicyText>
        <b>Category K: Inferences drawn from other personal information.</b>
        <br />
        Examples: Profile reflecting a person's preferences, characteristics,
        psychological trends, predispositions, behavior, attitudes,
        intelligence, abilities, and aptitudes.
        <br />
        Collected: No.
      </PolicyText>
      <PolicyText>
        <b>Category L: Sensitive personal information.</b>
        <br />
        Examples: Account login and password information, geolocation data,
        collection of images taken during the selfie progress
        <br />
        Collected: Yes.
      </PolicyText>

      <PolicyText>
        Under CCPA/CPRA, personal information does not include: Publicly
        available information from government records • Deidentified or
        aggregated consumer information • Information excluded from the
        CCPA/CPRA's scope, such as:
        <StyledList>
          <li>
            Health or medical information covered by the Health Insurance
            Portability and Accountability Act of 1996 (HIPAA) and the
            California Confidentiality of Medical Information Act (CMIA) or
            clinical trial data
          </li>
          <li>
            Personal Information covered by certain sector-specific privacy
            laws, including the Fair Credit Reporting Act (FRCA), the
            Gramm-Leach-Bliley Act (GLBA) or California Financial Information
            Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994
          </li>
        </StyledList>
      </PolicyText>

      <PolicySubtitle>Sources of Personal Information </PolicySubtitle>
      <PolicyText>
        We obtain the categories of personal information listed above from the
        following categories of sources:
        <StyledList>
          <li>
            <b>Directly from You. </b>
            For example, from the forms You complete on our Service, preferences
            You express or provide through our Service, or from Your purchases
            on our Service.
          </li>
          <li>
            <b>Indirectly from You. </b>
            For example, from observing Your activity on our Service.
          </li>
          <li>
            <b>Automatically from You. </b>
            For example, through cookies We or our Service Providers set on Your
            Device as You navigate through our Service.
          </li>
          <li>
            <b>From Service Providers. </b>
            For example, third-party vendors to monitor and analyze the use of
            our Service, third-party vendors to deliver targeted advertising to
            You, third-party vendors for payment processing, or other
            third-party vendors that We use to provide the Service to You.
          </li>
        </StyledList>
      </PolicyText>

      <PolicySubtitle>Use of Personal Information </PolicySubtitle>
      <PolicyText>
        We may use or disclose personal information We collect for "business
        purposes" or "commercial purposes" (as defined under the CCPA/CPRA),
        which may include the following examples:
        <StyledList>
          <li>To operate our Service and provide You with Our Service.</li>
          <li>
            To provide You with support and to respond to Your inquiries,
            including to investigate and address Your concerns and monitor and
            improve our Service.
          </li>
          <li>
            To fulfill or meet the reason You provided the information. For
            example, if You share Your contact information to ask a question
            about our Service, We will use that personal information to respond
            to Your inquiry. If You provide Your personal information to
            purchase a product or service, We will use that information to
            process Your payment and facilitate delivery.
          </li>
          <li>
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </li>
          <li>
            As described to You when collecting Your personal information or as
            otherwise set forth in the CCPA/CPRA.
          </li>
          <li>For internal administrative and auditing purposes.</li>
          <li>
            To detect security incidents and protect against malicious,
            deceptive, fraudulent or illegal activity, including, when
            necessary, to prosecute those responsible for such activities.
          </li>
          <li>Other one-time uses.</li>
          <br />
          Please note that the examples provided above are illustrative and not
          intended to be exhaustive. For more details on how we use this
          information, please refer to the "Use of Your Personal Data" section.
          If We decide to collect additional categories of personal information
          or use the personal information We collected for materially different,
          unrelated, or incompatible purposes We will update this Privacy
          Policy.
        </StyledList>
      </PolicyText>

      <PolicySubtitle>Disclosure of Personal Information</PolicySubtitle>
      <PolicyText>
        We may use or disclose and may have used or disclosed in the last twelve
        (12) months the following categories of personal information for
        business or commercial purposes:
        <StyledList>
          <li>Category A: Identifiers</li>
          <li>
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e))
          </li>
          <li>Category D: Commercial information</li>
          <li>Category F: Internet or other similar network activity</li>
          <li>Category G: Geolocation data</li>
          <li>Category L: Sensitive personal information</li>
          <br />
          Please note that the categories listed above are those defined in the
          CCPA/CPRA. This does not mean that all examples of that category of
          personal information were in fact disclosed, but reflects our good
          faith belief to the best of our knowledge that some of that
          information from the applicable category may be and may have been
          disclosed. When We disclose personal information for a business
          purpose or a commercial purpose, We enter a contract that describes
          the purpose and requires the recipient to both keep that personal
          information confidential and not use it for any purpose except
          performing the contract.
        </StyledList>
      </PolicyText>

      <PolicySubtitle>Share of Personal Information</PolicySubtitle>
      <PolicyText>
        We may share, and have shared in the last twelve (12) months, Your
        personal information identified in the above categories with the
        following categories of third parties:
        <StyledList>
          <li>Service Providers</li>
          <li>Payment processors</li>
          <li>Our affiliates</li>
          <li>Our business partners</li>
          <li>
            Third party vendors to whom You or Your agents authorize Us to
            disclose Your personal information in connection with products or
            services We provide to You
          </li>
        </StyledList>
      </PolicyText>

      <PolicySubtitle>Sale of Personal Information</PolicySubtitle>
      <PolicyText>
        As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting,
        releasing, disclosing, disseminating, making available, transferring, or
        otherwise communicating orally, in writing, or by electronic or other
        means, a Consumer's personal information by the Business to a third
        party for valuable consideration. This means that We may have received
        some kind of benefit in return for sharing personal information, but not
        necessarily a monetary benefit. We do not sell personal information as
        the term sell is commonly understood. We do allow Service Providers to
        use Your personal information for the business purposes described in Our
        Privacy Policy, for activities such as advertising, marketing, and
        analytics, and these may be deemed a sale under CCPA/CPRA. We may sell
        and may have sold in the last twelve (12) months the following
        categories of personal information:
        <br />
        <br />
        <StyledList>
          <li>Category A: Identifiers</li>
          <li>
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e))
          </li>
          <li>Category D: Commercial information</li>
          <li>Category F: Internet or other similar network activity</li>
          <li>Category G: Geolocation data</li>
          <li>Category L: Sensitive personal information</li>
          <br />
          Please note that the categories listed above are those defined in the
          CCPA/CPRA. This does not mean that all examples of that category of
          personal information were in fact sold, but reflects our good faith
          belief to the best of Our knowledge that some of that information from
          the applicable category may be and may have been shared for value in
          return.
        </StyledList>
      </PolicyText>

      <PolicySubtitle>
        Sale of Personal Information of Minors Under 16 Years of Age
      </PolicySubtitle>
      <PolicyText>
        We do not knowingly collect personal information from minors under the
        age of 16 through our Service, although certain third party websites
        that we link to may do so. These thirdparty websites have their own
        terms of use and privacy policies and We encourage parents and legal
        guardians to monitor their children's Internet usage and instruct their
        children to never provide information on other websites without their
        permission. We do not sell the personal information of Consumers We
        actually know are less than 16 years of age, unless We receive
        affirmative authorization (the "right to opt-in") from either the
        Consumer who is between 13 and 16 years of age, or the parent or
        guardian of a Consumer less than 13 years of age. Consumers who opt-in
        to the sale of personal information may opt-out of future sales at any
        time. To exercise the right to opt-out, You (or Your authorized
        representative) may submit a request to Us by contacting Us. If You have
        reason to believe that a child under the age of 13 (or 16) has provided
        Us with personal information, please contact Us with sufficient detail
        to enable Us to delete that information.
      </PolicyText>

      <PolicySubtitle>Your Rights under the CCPA/CPRA</PolicySubtitle>
      <PolicyText>
        The CCPA/CPRA provides California residents with specific rights
        regarding their personal information. If You are a resident of
        California, You have the following rights: <br />
        <b>The right to notice. </b>You have the right to be notified which
        categories of Personal Data are being collected and the purposes for
        which the Personal Data is being used.
        <br /> <br />
        <b>The right to know/access. </b>
        Under CCPA/CPRA, You have the right to request that We disclose
        information to You about Our collection, use, sale, disclosure for
        business purposes and share of personal information. Once We receive and
        confirm Your request, We will disclose to You:
        <StyledList>
          <li>The categories of personal information We collected about You</li>
          <li>
            The categories of sources for the personal information We collected
            about You
          </li>
          <li>
            Our business or commercial purposes for collecting or selling that
            personal information
          </li>
          <li>
            The categories of third parties with whom We share that personal
            information • The specific pieces of personal information We
            collected about You
          </li>
          <li>
            If we sold Your personal information or disclosed Your personal
            information for a business purpose, We will disclose to You:
          </li>
          <li>The categories of personal information categories sold</li>
          <li>The categories of personal information categories disclosed</li>
        </StyledList>
        <b>
          The right to say no to the sale or sharing of Personal Data (opt-out).{" "}
        </b>
        You have the right to direct Us to not sell Your personal information.
        To submit an opt-out request, please see the "Do Not Sell My Personal
        Information" section or contact Us.
        <br />
        <br />
        <b>The right to correct Personal Data. </b>
        You have the right to correct or rectify any inaccurate personal
        information about You that We collected. Once We receive and confirm
        Your request, We will use commercially reasonable efforts to correct
        (and direct our Service Providers to correct) Your personal information,
        unless an exception applies.
        <br />
        <br />
        <b>
          The right to limit use and disclosure of sensitive Personal Data.{" "}
        </b>
        You have the right to request to limit the use or disclosure of certain
        sensitive personal information We collected about You, unless an
        exception applies. To submit, please see the "Limit the Use or
        Disclosure of My Sensitive Personal Information" section or contact Us.
        <br />
        <br />
        <b>The right to delete Personal Data. </b>
        You have the right to request the deletion of Your Personal Data under
        certain circumstances, subject to certain exceptions. Once We receive
        and confirm Your request, We will delete (and direct Our Service
        Providers to delete) Your personal information from our records, unless
        an exception applies. We may deny Your deletion request if retaining the
        information is necessary for Us or Our Service Providers to:
        <StyledList>
          <li>
            Complete the transaction for which We collected the personal
            information, provide a good or service that You requested, take
            actions reasonably anticipated within the context of our ongoing
            business relationship with You, or otherwise perform our contract
            with You.
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity, or prosecute those responsible for
            such activities.
          </li>
          <li>
            Debug products to identify and repair errors that impair existing
            intended functionality.
          </li>
          <li>
            Exercise free speech, ensure the right of another consumer to
            exercise their free speech rights, or exercise another right
            provided for by law.
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act
            (Cal. Penal Code § 1546 et. seq.).
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when the information's
            deletion may likely render impossible or seriously impair the
            research's achievement, if You previously provided informed consent.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with
            consumer expectations based on Your relationship with Us.
          </li>
          <li>Comply with a legal obligation.</li>
          <li>
            Make other internal and lawful uses of that information that are
            compatible with the context in which You provided it.
          </li>
        </StyledList>
        <br />
        <PolicyText>
          <b>The right not to be discriminated against. </b>
          You have the right not to be discriminated against for exercising any
          of Your consumer's rights, including by:
          <StyledList>
            <li>Denying goods or services to You</li>
            <li>
              Charging different prices or rates for goods or services,
              including the use of discounts or other benefits or imposing
              penalties
            </li>
            <li>
              Providing a different level or quality of goods or services to You
            </li>
            <li>
              Suggesting that You will receive a different price or rate for
              goods or services or a different level or quality of goods or
              services
            </li>
          </StyledList>
        </PolicyText>
      </PolicyText>

      <PolicySubtitle>
        Exercising Your CCPA/CPRA Data Protection Rights
      </PolicySubtitle>
      <PolicyText>
        Please see the "Do Not Sell My Personal Information" section and "Limit
        the Use or Disclosure of My Sensitive Personal Information" section for
        more information on how to opt out and limit the use of sensitive
        information collected. Additionally, in order to exercise any of Your
        rights under the CCPA/CPRA, and if You are a California resident, You
        can contact Us:
        <StyledList>
          <li>
            By email:
            <StyledLink
              href={`mailto:${EMAIL}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {EMAIL}
            </StyledLink>
          </li>
        </StyledList>
        <br />
        Only You, or a person registered with the California Secretary of State
        that You authorize to act on Your behalf, may make a verifiable request
        related to Your personal information.
        <br />
        <br />
        Your request to Us must:
        <StyledList>
          <li>
            Provide sufficient information that allows Us to reasonably verify
            You are the person about whom We collected personal information or
            an authorized representative
          </li>
          <li>
            Describe Your request with sufficient detail that allows Us to
            properly understand, evaluate, and respond to it
          </li>
        </StyledList>
        <br />
        We cannot respond to Your request or provide You with the required
        information if We cannot:
        <StyledList>
          <li>Verify Your identity or authority to make the request</li>
          <li>And confirm that the personal information relates to You</li>
        </StyledList>
        <br />
        We will disclose and deliver the required information free of charge
        within 45 days of receiving Your verifiable request. The time period to
        provide the required information may be extended once by an additional
        45 days when reasonably necessary and with prior notice. Any disclosures
        We provide will only cover the 12-month period preceding the verifiable
        request's receipt. For data portability requests, We will select a
        format to provide Your personal information that is readily usable and
        should allow You to transmit the information from one entity to another
        entity without hindrance.
      </PolicyText>

      <PolicySubtitle>Do Not Sell My Personal Information</PolicySubtitle>
      <PolicyText>
        As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting,
        releasing, disclosing, disseminating, making available, transferring, or
        otherwise communicating orally, in writing, or by electronic or other
        means, a Consumer's personal information by the Business to a third
        party for valuable consideration. This means that We may have received
        some kind of benefit in return for sharing personal information, but not
        necessarily a monetary benefit. We do not sell personal information as
        the term sell is commonly understood. We do allow Service Providers to
        use Your personal information for the business purposes described in Our
        Privacy Policy, for activities such as advertising, marketing, and
        analytics, and these may be deemed a sale under CCPA/CPRA. You have the
        right to opt-out of the sale of Your personal information. Once We
        receive and confirm a verifiable consumer request from You, we will stop
        selling Your personal information. To exercise Your right to opt-out,
        please contact Us. The Service Providers we partner with (for example,
        our analytics or advertising partners) may use technology on the Service
        that sells personal information as defined by the CCPA/ CPRA law. If you
        wish to opt out of the use of Your personal information for
        interest-based advertising purposes and these potential sales as defined
        under CCPA/CPRA law, you may do so by following the instructions below.
        Please note that any opt out is specific to the browser You use. You may
        need to opt out on every browser that You use.
      </PolicyText>

      <PolicySubtitle>Website</PolicySubtitle>
      <PolicyText>
        If applicable, click "Privacy Preferences", "Update Privacy Preferences"
        or "Do Not Sell My Personal Information" buttons listed on the Service
        to review your privacy preferences and opt out of cookies and other
        technologies that We may use. Please note that You will need to opt out
        from each browser that You use to access the Service. Additionally, You
        can opt out of receiving ads that are personalized as served by our
        Service Providers by following our instructions presented on the
        Service:
        <StyledList>
          <li>
            The NAI's opt-out platform:
            <StyledLink
              href="http://www.networkadvertising.org/choices/"
              rel="noopener noreferrer"
              target="_blank"
            >
              http://www.networkadvertising.org/choices/
            </StyledLink>
          </li>
          <li>
            The EDAA's opt-out platform
            <StyledLink
              href="http://www.youronlinechoices.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              http://www.youronlinechoices.com/
            </StyledLink>
          </li>
          <li>
            The DAA's opt-out platform:
            <StyledLink
              href="http://optout.aboutads.info/?c=2&lang=EN"
              rel="noopener noreferrer"
              target="_blank"
            >
              http://optout.aboutads.info/?c=2&lang=EN
            </StyledLink>
          </li>
        </StyledList>
        <br />
        The opt out will place a cookie on Your computer that is unique to the
        browser You use to opt out. If you change browsers or delete the cookies
        saved by your browser, You will need to opt out again.
      </PolicyText>

      <PolicySubtitle>Mobile Devices</PolicySubtitle>
      <PolicyText>
        Your mobile device may give You the ability to opt out of the use of
        information about the apps You use in order to serve You ads that are
        targeted to Your interests:
        <StyledList>
          <li>
            "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization"
            on Android devices
          </li>
          <li>"Limit Ad Tracking" on iOS devices</li>
        </StyledList>
        <br />
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on Your mobile device.
      </PolicyText>

      <PolicySubtitle>
        Limit the Use or Disclosure of My Sensitive Personal Information
      </PolicySubtitle>
      <PolicyText>
        If You are a California resident, You have the right to limit the use
        and disclosure of Your sensitive personal information to that use which
        is necessary to perform the services or provide the goods reasonably
        expected by an average Consumer who requests such services or goods. We
        collect, use and disclose sensitive personal information in ways that
        are necessary to provide the Service. For more information on how We use
        Your personal information, please see the "Use of Your Personal Data"
        section or contact us.
      </PolicyText>
    </PolicyItemWrapper>
  );
};
