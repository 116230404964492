import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

import { PADDING } from "../lib/constants";
import { colors, fontFamily } from "../UIKit";

export const TitleBlock = styled.div`
  width: ${(props) => `${props.width}px`};
  text-align: center;
  margin: 0 auto;
`;

export const Title = styled.div`
  font-family: ${fontFamily.monument.monumentExtended};
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: ${(props) => (props.$lh ? `${props.$lh}%` : "125%")};
  text-align: ${(props) => (props.$align ? props.$align : "center")};
  font-size: ${(props) => (props.$fs ? `${props.$fs}px` : "32px")};
  margin-bottom: ${(props) => (props.$mb ? `${props.$mb}px` : `${PADDING}px`)};
  margin-top: ${(props) => (props.$mt ? `${props.$mt}px` : 0)};
  color: ${(props) => (props.color ? props.color : `${colors.base.black}`)};
  z-index: 10;
`;

export const Text = styled.div`
  text-align: ${(props) => (props.$align ? `${props.$align}` : "center")};
  font-family: ${(props) =>
    props.$ff ? `${props.$ff}` : `${fontFamily.redHat.regular}`};
  font-size: ${(props) => (props.$fsz ? `${props.$fsz}px` : "15px")};
  line-height: ${(props) => (props.$lh ? `${props.$lh}%` : "134%")};
  letter-spacing: ${(props) => (props.$ls ? `${props.$ls}px` : "0.15px")};
  color: ${(props) => (props.color ? props.color : `${colors.base.black}`)};
  padding-bottom: ${(props) => (props.$pb ? `${props.$pb}px` : `0px`)};
  text-transform: ${(props) => (props.$tt ? `${props.$tt}` : `none`)};
  text-decoration: ${(props) => (props.$crossedOut ? `line-through` : `none`)};
`;

export const Button = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  text-align: ${(props) => (props.$align ? `${props.$align}` : "center")};
  font-family: ${(props) =>
    props.$ff ? `${props.$ff}` : `${fontFamily.redHat.semiBold}`};
  font-size: ${(props) => (props.$fsz ? `${props.$fsz}px` : "15px")};
  line-height: ${(props) => (props.$lh ? `${props.$lh}%` : "132%")};
  letter-spacing: ${(props) => (props.$ls ? `${props.$ls}px` : "2.4px")};
  color: ${(props) => (props.color ? props.color : `${colors.base.white}`)};
  padding-bottom: ${(props) => (props.$pb ? `${props.$pb}px` : `0px`)};
  padding-top: ${(props) => (props.$pt ? `${props.$pt}px` : `0px`)};
  padding-left: ${(props) => (props.$pl ? `${props.$pl}px` : `0px`)};
  padding-right: ${(props) => (props.$pr ? `${props.$pr}px` : `0px`)};
  width: ${(props) => (props.$width ? `${props.$width}px` : `100%`)};
  background-color: ${(props) =>
    props.$bg ? `${props.$bg}` : `${colors.base.black}}`};
`;

export const SvgWrapper = styled.div`
  margin-right: 44px;
`;

const baseLinkStyles = `
  font-family: ${fontFamily.redHat.medium};
  font-size: 15px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  cursor: pointer;

`;

export const StyledHashLink = styled(HashLink)`
  ${baseLinkStyles}
  line-height: ${(props) => (props.$lh ? `${props.$lh}%` : "121%")};
  color: ${(props) => (props.color ? props.color : `${colors.base.black}`)};
`;

export const LinkItem = styled.a`
  ${baseLinkStyles}
  line-height: ${(props) => (props.$lh ? `${props.$lh}%` : "121%")};
  color: ${(props) => (props.color ? props.color : `${colors.base.black}`)};
`;
