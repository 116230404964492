import React from "react";
import { useLocation } from "react-router-dom";

import { oldWebsite } from "../../lib/constants";
import { StyledHashLink } from "../../pages/commonStyled";
import {
  AppleStore,
  colors,
  Instagram,
  Logo,
  TikTok,
  Youtube,
} from "../../UIKit";
import { navPages } from "../data";
import {
  ButtonsWrapper,
  HeaderContainer,
  NavLink,
  NavLinks,
  NavLinksWrapper,
  SocialLinks,
  StyledButton,
  StyledLogo,
  Wrapper,
} from "./styled";

export const Header = () => {
  const location = useLocation();
  const bgColor = location.pathname === "/" ? colors.bg : colors.base.white;

  return (
    <HeaderContainer $bgColor={bgColor}>
      <NavLinksWrapper>
        <StyledLogo>
          <Logo width={48} height={48} />
        </StyledLogo>
        <NavLinks>
          {navPages.map((item, index) => (
            <NavLink key={index}>
              <StyledHashLink to={item.page}>{item.name}</StyledHashLink>
            </NavLink>
          ))}
        </NavLinks>
      </NavLinksWrapper>
      <ButtonsWrapper>
        <Wrapper>
          <AppleStore width={112} height={32} />
          <StyledButton href={oldWebsite} target="_blank" rel="noreferrer">
            back to old version
          </StyledButton>
        </Wrapper>
        <SocialLinks>
          <Instagram width={24} height={24} />
          <TikTok width={24} height={24} />
          <Youtube width={24} height={24} />
        </SocialLinks>
      </ButtonsWrapper>
    </HeaderContainer>
  );
};
