export const PADDING = 24;

export const MOBILE_DEFAULT_PADDING = 40;

export const INDENT_BETWEEN_PAGES = 120;

export const WINDOW_WIDTH = window.innerWidth;

export const tikTokUrl = "https://www.tiktok.com/@allyoucanface";

export const youtubeUrl = "https://www.youtube.com/@AllYouCanFace";

export const instagramUrl = "https://www.instagram.com/allyoucanface/";

export const oldWebsite = "https://program.allyoucanface.com/wp-login.php";

export const DOWNLOAD_APP_STORE_URL =
  "https://apps.apple.com/de/app/id6453606687";

export const DOWNLOAD_GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.program.allyoucanfacev2.app";

export const PRIVACY_POLICY_URL = "https://allyoucanface.com/privacy-policy/";

export const TERMS_OF_USE_URL = "https://allyoucanface.com/terms-of-use/";

const USER_AGENT_ANDROID = window.navigator.userAgent.match(/Android/i);

export const SUBSCRIPTION_BUTTON_URL = USER_AGENT_ANDROID
  ? DOWNLOAD_GOOGLE_PLAY_URL
  : DOWNLOAD_APP_STORE_URL;

export const MAIN_SITE_URL = "https://allyoucanface.com";

export const EMAIL = "mail@allyoucanface.com";
