import styled from "styled-components";

import { PADDING } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${PADDING}px 64px;
  width: 100%;
  background-color: ${(props) => props.$bgColor && props.$bgColor};
`;

export const StyledLogo = styled.div`
  cursor: pointer;
`;

export const NavLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`;

export const NavLinks = styled.ul`
  display: flex;
`;

export const NavLink = styled.li`
  padding: 0 2rem;
  font-family: ${fontFamily.redHat.regular};
  font-weight: 500;
  font-size: 15px;
  line-height: 132%;
  letter-spacing: 2.4px;
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 12px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const Wrapper = styled(ButtonsWrapper)`
  gap: 16px;
  align-items: flex-start;
`;

export const StyledButton = styled.a`
  padding: 8px 16px;
  border: 1px solid ${colors.base.black};
  text-transform: uppercase;
  font-family: ${fontFamily.redHat.semiBold};
  font-size: 11px;
  line-height: 125%;
  letter-spacing: 1.76px;
  cursor: pointer;
  border-radius: 2px;
`;
