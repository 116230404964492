import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

import { SliderArrow } from "../../components/SliderArrow";
import { colors } from "../../UIKit";
import { reviewsData } from "./data";

export const ReviewsSlider = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings}>
      {reviewsData.map((item, index) => (
        <Card key={index}>
          <CardImage src={item.path} alt={item.alt} />
        </Card>
      ))}
    </Slider>
  );
};

const NextArrow = (props) => {
  return <SliderArrow {...props} direction="next" />;
};

const PrevArrow = (props) => {
  return <SliderArrow {...props} direction="back" />;
};

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 312px;
  border: 1px solid ${colors.gray[100]};
  position: relative;
  border-radius: 8px;
  padding: 20px 20px 26px 20px;
`;

const CardImage = styled.img`
  max-width: 90%;
  max-height: 80%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
