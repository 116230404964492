import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { validationSchema } from "../../lib/formValidation";
import { PopUp } from "./PopUp";

export const PopUpContainer = ({
  handleClose,
  showModal,
  showConfirmContent,
  setShowConfirmContent,
}) => {
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (error) {
      const timeoutId = setTimeout(() => {
        setError("");
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [error]);

  const handleCloeErrorNotifier = () => {
    setError("");
  };

  const handleSubscription = async (data) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/potential_students`, {
        potential_student: {
          name: data.name,
          email: data.email,
        },
      });

      setShowConfirmContent(true);
      reset();
    } catch (error) {
      setError(error.response?.data?.message[0]);
    }
  };

  const onSubmit = (data) => handleSubscription(data);

  return (
    <PopUp
      onSubmit={onSubmit}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      handleClose={handleClose}
      showModal={showModal}
      showConfirmContent={showConfirmContent}
      error={error}
      handleCloeErrorNotifier={handleCloeErrorNotifier}
    />
  );
};
