import reviews from "../../assets/reviews";

export const reviewsData = [
  { path: reviews.Review4, alt: "review.png" },
  { path: reviews.Review2, alt: "review.png" },
  { path: reviews.Review3, alt: "review.png" },
  { path: reviews.Review5, alt: "review.png" },
  { path: reviews.Review6, alt: "review.png" },
  { path: reviews.Review7, alt: "review.png" },
  { path: reviews.Review8, alt: "review.png" },
  { path: reviews.Review9, alt: "review.png" },
  { path: reviews.Review10, alt: "review.png" },
  { path: reviews.Review11, alt: "review.png" },
  { path: reviews.Review12, alt: "review.png" },
  { path: reviews.Review13, alt: "review.png" },
  { path: reviews.Review14, alt: "review.png" },
  { path: reviews.Review15, alt: "review.png" },
  { path: reviews.Review16, alt: "review.png" },
  { path: reviews.Review17, alt: "review.png" },
  { path: reviews.Review18, alt: "review.png" },
  { path: reviews.Review19, alt: "review.png" },
  { path: reviews.Review20, alt: "review.png" },
  { path: reviews.Review21, alt: "review.png" },
  { path: reviews.Review22, alt: "review.png" },
  { path: reviews.Review23, alt: "review.png" },
  { path: reviews.Review24, alt: "review.png" },
  { path: reviews.Review25, alt: "review.png" },
  { path: reviews.Review26, alt: "review.png" },
];
