import styled from "styled-components";

import { PADDING, MOBILE_DEFAULT_PADDING } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.$bgColor && props.$bgColor};
  padding: 0 ${PADDING}px;
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

export const BurgerInner = styled.div`
  background-color: ${(props) => props.$bgColor && props.$bgColor};
  z-index: 10;
`;

export const NavWrapper = styled.div`
  padding: ${MOBILE_DEFAULT_PADDING}px 0;
`;

export const NavLinks = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: ${PADDING}px;
`;

export const SocialBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: ${MOBILE_DEFAULT_PADDING}px;
`;

export const NavLink = styled.li`
  font-family: ${fontFamily.redHat.semiBold};
  font-size: 15px;
  line-height: 132%;
  letter-spacing: 2.4px;
  color: ${colors.base.black};
`;

export const OldSiteButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: ${fontFamily.redHat.semiBold};
  font-size: 15px;
  line-height: 132%;
  letter-spacing: 2.4px;
  border-top: 1px solid ${colors.gray[200]};
  border-bottom: 1px solid ${colors.gray[200]};
  padding: 22px 0;
`;

export const DownloadButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: ${MOBILE_DEFAULT_PADDING}px;
  padding-bottom: 32px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
