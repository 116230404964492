export const fontFamily = {
  redHat: {
    regular: "RedHatRegular",
    medium: "RedHatMedium",
    semiBold: "RedHatSemiBold",
    bold: "RedHatBold",
  },
  monument: {
    monumentExtended: "MonumentExtended",
  },
};
