import styled from "styled-components";
import { Link } from "react-router-dom";

import { INDENT_BETWEEN_PAGES, PADDING } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";

export const Container = styled.footer`
  padding: 48px 64px;
  background-color: ${colors.base.black};

  @media (max-width: 768px) {
    padding: 48px ${PADDING}px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${INDENT_BETWEEN_PAGES}px;

  @media (max-width: 768px) {
    padding-bottom: 64px;
  }
`;

export const Navigation = styled.div`
  display: flex;
  min-width: 328px;
  flex-direction: column;
  gap: ${PADDING}px;

  @media (max-width: 768px) {
    width: auto;
    min-width: auto;
  }
`;

export const NavTitle = styled.div`
  font-family: ${fontFamily.redHat.bold};
  font-size: 15px;
  line-height: 134%;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: ${colors.base.white};
`;

export const NavLinks = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const NavLinksBlock = styled.div`
  display: flex;
  gap: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const NavLink = styled.li`
  margin: 6px 0;
`;

export const StyledNavLink = styled(Link)`
  font-family: ${fontFamily.redHat.medium};
  font-size: 15px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  line-height: ${(props) => (props.$lh ? `${props.$lh}%` : "121%")};
  color: ${colors.base.white};
  cursor: pointer;
  padding-bottom: 8px;
`;

export const NavItem = styled(StyledNavLink)``;

export const Social = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    margin-top: 80px;
  }
`;

export const CopyrightCompany = styled.div`
  font-family: ${fontFamily.redHat.regular};
  font-size: 13px;
  color: ${colors.opacity.white[60]};
  letter-spacing: 0.13px;
  line-height: 134%;
  text-transform: uppercase;
`;

export const CopyrightDeveloped = styled(CopyrightCompany)``;

export const DownloadButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
