import React from "react";

import images from "../../assets/images";
import { AppleStore, GooglePlay } from "../../UIKit";
import { Text, Title, TitleBlock } from "../commonStyled";
import { Container, Content, Mockups, StyledGradient } from "./MobileStyled";
import { ButtonsWrapper, Gradient } from "./styled";

export const MobileMain = () => {
  return (
    <Container id="/">
      <Content>
        <StyledGradient>
          <Gradient />
          <Mockups
            width={252}
            height={280}
            src={images.Mockups}
            alt="mockups.pic"
          />
        </StyledGradient>
        <TitleBlock width={327}>
          <Title $fs={27} $mb={12} $mt={48}>
            Revolutionize your Beauty & Wellness with Face Yoga
          </Title>
        </TitleBlock>
        <Text $fsz={20} $lh={120} $ls={0.2}>
          Unleash your beauty and self-confidence with a personalized workout
          program.
        </Text>
        <ButtonsWrapper>
          <AppleStore width={160} height={44} />
          <GooglePlay width={160} height={44} />
        </ButtonsWrapper>
      </Content>
    </Container>
  );
};
