import React from "react";

import { Text } from "../../pages/commonStyled";
import { ClosePopUp, fontFamily } from "../../UIKit";
import { CloseErrorNotifier, ErrorWrapper } from "./styled";

export const ErrorNotifier = ({ error, handleCloeErrorNotifier }) => {
  return (
    <ErrorWrapper style={{ opacity: error ? 1 : 0 }}>
      <CloseErrorNotifier onClick={handleCloeErrorNotifier}>
        <ClosePopUp width={10} height={19} />
      </CloseErrorNotifier>
      <Text $lh={125} $ff={fontFamily.redHat.medium} $align="left" $fsz={14}>
        {error}
      </Text>
    </ErrorWrapper>
  );
};
