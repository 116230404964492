import React from "react";

import { StyledHashLink } from "../../pages/commonStyled";
import { colors } from "../../UIKit";
import { navPages } from "../data";
import {
  Navigation,
  NavLink,
  NavLinks,
  NavLinksBlock,
  NavTitle,
  StyledNavLink,
} from "./styled";

export const FooterNavigation = () => {
  return (
    <Navigation>
      <NavTitle>NAVIGATION</NavTitle>
      <NavLinksBlock>
        <NavLinks>
          {navPages.map((item, index) => (
            <NavLink key={index}>
              <StyledHashLink color={colors.base.white} to={item.page}>
                {item.name}
              </StyledHashLink>
            </NavLink>
          ))}
        </NavLinks>
        <NavLinks>
          <StyledNavLink to="/terms-and-conditions">
            Terms and conditions
          </StyledNavLink>
          <StyledNavLink to="/privacy-policy">privacy policy</StyledNavLink>
        </NavLinks>
      </NavLinksBlock>
    </Navigation>
  );
};
