import styled from "styled-components";

import { INDENT_BETWEEN_PAGES, PADDING } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${INDENT_BETWEEN_PAGES}px;

  @media (max-width: 768px) {
    padding: 96px ${PADDING}px;
  }
`;

export const QuestionsBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Question = styled.div`
  display: flex;
  gap: 16px;
  cursor: pointer;
`;

export const FaqItem = styled.li`
  display: flex;
  gap: 80px;
  padding: 40px 0;
  border-bottom: ${(props) =>
    props.$lastItem ? "none" : `1px solid ${colors.gray[200]}`};

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export const QuestionText = styled.div`
  font-size: 22px;
  padding-top: 5px;
  font-family: ${fontFamily.redHat.bold};
  line-height: 121%;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  width: ${(props) => (props.selected ? "472px" : "auto")};
  color: ${(props) =>
    props.selected ? `${colors.primary[700]}` : `${colors.base.black}`};
`;

export const Answer = styled.div`
  font-family: ${fontFamily.redHat.regular};
  font-size: 17px;
  line-height: 140%;
  letter-spacing: 0.17px;
`;
