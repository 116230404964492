import React, { useState } from "react";

import { WINDOW_WIDTH } from "../../lib/constants";
import { colors, FaqArrow, fontFamily } from "../../UIKit";
import { Text, Title, TitleBlock } from "../commonStyled";
import { faqData } from "./data";
import {
  Answer,
  Container,
  FaqItem,
  Question,
  QuestionsBlock,
  QuestionText,
} from "./styled";

export const FAQ = () => {
  const [selectedItemIndices, setSelectedItemIndices] = useState([]);

  const handleSelected = (index) => {
    if (selectedItemIndices.includes(index)) {
      setSelectedItemIndices(
        selectedItemIndices.filter((item) => item !== index)
      );
    } else {
      setSelectedItemIndices([...selectedItemIndices, index]);
    }
  };

  return (
    <Container id="faq">
      <TitleBlock width={WINDOW_WIDTH > 768 ? 800 : 327}>
        <Text
          $ff={fontFamily.redHat.semiBold}
          $fsz={15}
          $lh={121}
          $ls={2.4}
          $tt="uppercase"
          color={colors.primary[500]}
        >
          Faq
        </Text>
        <Title
          $fs={WINDOW_WIDTH > 768 ? 32 : 24}
          $mt={12}
          $mb={WINDOW_WIDTH > 768 ? 80 : 64}
        >
          Frequently asked Questions
        </Title>
      </TitleBlock>
      <QuestionsBlock>
        <ul>
          {faqData.map((item, index) => (
            <FaqItem key={index} $lastItem={index === faqData.length - 1}>
              <Question onClick={() => handleSelected(index)}>
                <FaqArrow
                  width={32}
                  height={32}
                  color={colors.primary[500]}
                  isSelected={selectedItemIndices.includes(index)}
                />
                <QuestionText selected={selectedItemIndices.includes(index)}>
                  {item.question}
                </QuestionText>
              </Question>
              {selectedItemIndices.includes(index) && (
                <Answer>{item.answer}</Answer>
              )}
            </FaqItem>
          ))}
        </ul>
      </QuestionsBlock>
    </Container>
  );
};
