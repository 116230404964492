// import images from "../../assets/images";
import { WINDOW_WIDTH } from "../../lib/constants";
import { colors } from "../../UIKit";
import {
  NumberFour,
  NumberOne,
  NumberThree,
  NumberTwo,
} from "../../UIKit/icons";

const isMobile = WINDOW_WIDTH < 768;

export const adviceData = [
  {
    // imgPath: isMobile ? images.Method1 : null,
    number: (
      <NumberOne
        width={isMobile ? 30 : 72}
        height={isMobile ? 88 : 224}
        color={colors.primary[50]}
      />
    ),
    title: "ELEVATE YOUR BEAUTY EVERY DAY: PERSONALIZED FACE YOGA PROGRAM",
    text: "Experience the power of a tailored face yoga journey designed exclusively for you. Answer a few questions, and unlock a customized program that caters to your goals, addressing your specific needs. Enjoy the flexibility to practice anytime and anywhere and embrace a personal path to radiant beauty.",
  },
  {
    // imgPath: isMobile ? images.Method2 : null,
    number: (
      <NumberTwo
        width={isMobile ? 62 : 152}
        height={isMobile ? 88 : 224}
        color={colors.primary[50]}
      />
    ),
    title: "WITNESS YOUR TRANSFORMATION: TRACK, COMPARE & CELEBRATE",
    text: "Capture your progress and celebrate your achievements. Upload before and after pictures to see the transformative power of face yoga. Track your journey, compare results, and witness the amazing changes in your appearance.",
  },
  {
    // imgPath: isMobile ? images.Method3 : null,
    number: (
      <NumberThree
        width={isMobile ? 62 : 152}
        height={isMobile ? 88 : 224}
        color={colors.primary[50]}
      />
    ),
    title: "UNLEASH THE SECRETS: EXCLUSIVE SKINCARE & WELLNESS RESOURSES",
    text: "Access a treasure trove of knowledge and inspiration. Dive into a wealth of articles, cheat sheets, guides, and more on skincare, nutrition, and overall wellbeing. Empower yourself with expert insights and become the most beautiful and radiant version of yourself.",
  },
  {
    // imgPath: isMobile ? images.Method3 : null,
    number: (
      <NumberFour
        width={isMobile ? 72 : 152}
        height={isMobile ? 88 : 224}
        color={colors.primary[50]}
      />
    ),
    title: "GUIDANCE EVERY STEP OF THE WAY: EXPERT HELP AT YOUR FINGERTIPS",
    text: "We're here to support your face yoga journey. Find helpful info buttons throughout the app, offering guidance and answers to your questions. Our team is also available to assist you, ensuring a seamless and fulfilling experience.",
  },
];
