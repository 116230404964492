import React from "react";

import images from "../../assets/images";
import { Title } from "../commonStyled";
import {
  Container,
  Description,
  ImageWrapper,
  StepWrapper,
  Subtitle,
} from "./styled";

export const DropUser = () => {
  return (
    <Container>
      <Title $align="center" $fs={20} $mb={12}>
        Deleting Your Account from Allyoucanface
      </Title>
      <StepWrapper>
        <Subtitle>Step 1: Navigate to Your Profile</Subtitle>
        <Description>
          Once logged in, click on your profile picture in the top right corner
          of the screen.
          <ImageWrapper>
            <img
              src={images.DropUser1}
              width={200}
              height={400}
              alt="drop_user.pic"
            />
          </ImageWrapper>
        </Description>
      </StepWrapper>
      <StepWrapper>
        <Subtitle> Step 2: Access Profile Settings</Subtitle>
        <Description>Click the "Profile Settings" button</Description>
        <div>
          <ImageWrapper>
            <img
              src={images.DropUser2}
              width={200}
              height={400}
              alt="drop_user.pic"
            />
          </ImageWrapper>
        </div>
      </StepWrapper>
      <StepWrapper>
        <Subtitle> Step 3: Delete Account</Subtitle>
        <Description>
          Click the "Delete My Account" button to initiate the account deletion
          process.
          <ImageWrapper>
            <img
              src={images.DropUser3}
              width={200}
              height={400}
              alt="drop_user.pic"
            />
          </ImageWrapper>
        </Description>
      </StepWrapper>
      <StepWrapper>
        <Subtitle> Step 4: Confirmation</Subtitle>
        <Description>
          After you confirm account deletion - your account will be deleted from
          our application
        </Description>
      </StepWrapper>
    </Container>
  );
};
