import React, { useEffect, useState } from "react";

import images from "../../assets/images";
import { Methods } from "./Methods";

const imagesData = [
  { path: images.Method1 },
  { path: images.Method2 },
  { path: images.Method3 },
];

export const MethodsContainer = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [isMethodBlockVisible, setIsMethodBlockVisible] = useState(false);
  const [firstScrollY, setFirstScrollY] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const methodBlockElement = document.getElementById("rightBlock");

      if (methodBlockElement) {
        const elementPosition = methodBlockElement.getBoundingClientRect();

        const isVisible =
          elementPosition.top < window.innerHeight &&
          elementPosition.bottom >= 0;

        setIsMethodBlockVisible(isVisible);

        if (isVisible && firstScrollY === null) {
          setFirstScrollY(window.scrollY);
        }
      }
    };

    const handleScrollPosition = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScrollPosition);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScrollPosition);
    };
  }, [isMethodBlockVisible, firstScrollY]);

  useEffect(() => {
    if (scrollPosition !== null) {
      if (scrollPosition < firstScrollY + 400) {
        setImageIndex(0);
      } else if (
        scrollPosition >= firstScrollY + 400 &&
        scrollPosition < firstScrollY + 750
      ) {
        setImageIndex(1);
      } else {
        setImageIndex(2);
      }
    }
  }, [scrollPosition, isMethodBlockVisible, firstScrollY]);

  useEffect(() => {
    const blockTopValue = document.getElementById("methods").offsetTop;

    setFirstScrollY(blockTopValue);
  }, []);

  return <Methods imagesData={imagesData} imageIndex={imageIndex} />;
};
