import React from "react";

import { PolicyItemWrapper, PolicyText, PolicyTitle } from "../styled";

export const ChangesToPolicy = () => {
  return (
    <PolicyItemWrapper id="changes to policy">
      <PolicyTitle>Changes to this Privacy Policy</PolicyTitle>
      <PolicyText>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page. We will
        let You know via email and/or a prominent notice on Our Service, prior
        to the change becoming effective and update the "Last updated" date at
        the top of this Privacy Policy. You are advised to review this Privacy
        Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
      </PolicyText>
    </PolicyItemWrapper>
  );
};
