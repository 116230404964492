export const handleAppStoreClick = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "app_store_click",
  });
};

export const handleGooglePlayClick = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "google_play_click",
  });
};
