import React from "react";

import { WINDOW_WIDTH } from "../../lib/constants";
import { Button, Text, Title } from "../../pages/commonStyled";
import { ClosePopUp, fontFamily, Heart } from "../../UIKit";
import { ButtonWrapper, CloseModal, ContentWrapper } from "./styled";

export const ConfirmContent = ({ handleClose }) => {
  return (
    <ContentWrapper>
      <CloseModal onClick={handleClose}>
        <ClosePopUp width={12} height={23} />
      </CloseModal>
      <Heart width={23} height={23} />
      <Title $fs={16} $mt={13} $mb={1}>
        Thank you!
      </Title>
      <Text
        $lh={125}
        $ff={fontFamily.redHat.regular}
        $fsz={14}
        $pb={WINDOW_WIDTH > 768 ? 42 : 33}
      >
        Exciting news! Your Face Yoga Guide is on its way. Your facial wellness
        journey starts now!
      </Text>
      <ButtonWrapper>
        <Button
          onClick={handleClose}
          $pl={24}
          $pr={24}
          $pt={18}
          $pb={18}
          $width={200}
        >
          Close
        </Button>
      </ButtonWrapper>
    </ContentWrapper>
  );
};
