import styled from "styled-components";

import { INDENT_BETWEEN_PAGES, PADDING } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";

export const Container = styled.div`
  padding: ${INDENT_BETWEEN_PAGES}px;
  position: relative;

  @media (max-width: 768px) {
    padding: 96px ${PADDING}px;
  }
`;

export const BenefitsWrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BenefitItem = styled.div`
  display: flex;
  width: ${window.innerWidth - INDENT_BETWEEN_PAGES * 2}px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &:nth-child(2) {
    border-left: 1px solid ${colors.gray[100]};
    border-right: 1px solid ${colors.gray[100]};
  }

  @media (max-width: 768px) {
    width: 100%;

    &:nth-child(2) {
      border-top: 1px solid ${colors.gray[100]};
      border-bottom: 1px solid ${colors.gray[100]};
      border-left: none;
      border-right: none;
      margin-top: 40px;
      padding-bottom: 40px;
    }
  }
`;

export const GradientWrapper = styled.div`
  position: relative;
`;

export const BenefitTitle = styled.div`
  width: 320px;
  text-align: center;
  font-family: ${fontFamily.redHat.bold};
  position: absolute;
  top: 100px;
  font-size: 24px;
  line-height: 115%;

  @media (max-width: 768px) {
    top: 80px;
  }
`;

export const BenefitText = styled.div`
  text-align: center;
  font-family: ${fontFamily.redHat.regular};
  padding: 0 40px;
  font-size: 17px;
  line-height: 140%;
  letter-spacing: 0.17px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;
