import styled from "styled-components";

import { colors, fontFamily } from "../../UIKit";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 56px 321px 30px 380px;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 32px 20px 34px 20px;
  }
`;

export const StyledTitle = styled.h1`
  font-family: ${fontFamily.monument.monumentExtended};
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  align-self: start;
`;

export const StyledSubtitle = styled.h3`
  text-transform: uppercase;
  font-family: Jost;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 4px;
  padding-top: 30px;
`;

export const LinksWrapper = styled.ol`
  display: flex;
  flex-direction: column;
  text-decoration: underline;
  margin-left: 20px;
`;

export const LinkItem = styled.li`
  color: ${colors.base.black};
  font-family: Jost;
  font-size: 16px;
  font-weight: 300;
  line-height: 129.4%;
`;

export const TermWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const TermTitle = styled.h2`
  color: ${colors.base.black};
  font-family: Jost;
  font-size: 18px;
  font-weight: 500;
  line-height: 129.4%;
  text-transform: uppercase;
  padding-bottom: 4px;
`;

export const TermText = styled.p`
  color: ${colors.base.black};
  font-family: Jost;
  font-size: 16px;
  font-weight: 300;
  line-height: 129.4%;
`;

export const TermsDescription = styled(TermText)`
  padding: 30px 0;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  text-transform: none;
  font-family: Jost;
  font-size: 16px;
  font-weight: 300;
  line-height: 129.4%;
  cursor: pointer;
`;

export const DocumentUpdated = styled(TermText)`
  color: #888;
  padding-top: 30px;
`;
