import styled from "styled-components";

import { INDENT_BETWEEN_PAGES } from "../../lib/constants";
import { fontFamily } from "../../UIKit";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px ${INDENT_BETWEEN_PAGES}px ${INDENT_BETWEEN_PAGES}px
    ${INDENT_BETWEEN_PAGES}px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StepWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
`;

export const Subtitle = styled.div`
  font-family: ${fontFamily.redHat.regular};
  font-size: 22px;
  letter-spacing: 0.22px;
`;

export const Description = styled.div`
  text-align: center;
  font-family: ${fontFamily.redHat.regular};
  padding: 0 40px;
  font-size: 17px;
  line-height: 140%;
  letter-spacing: 0.17px;
`;
