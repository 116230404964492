import React from "react";

import { Title } from "../../pages/commonStyled";
import { BeforeAfterText } from "../../UIKit";
import {
  AllYouCanBecome,
  Button,
  Card,
  CardsWrapper,
  Container,
  TextWrapper,
  CardWrapper,
  TitleBlock,
  AfterHorizontalImg,
  BeforeHorizontalImg,
  StyledVerticalImage,
  StyledHorizontalImage,
  HorizontalCardWrapper,
  InnerGradient,
  BeforeVerticalBlock,
  AfterVerticalBlock,
} from "./styled";
import { cards } from "./data";
import { WINDOW_WIDTH } from "../../lib/constants";
import { CustomSlider } from "./Slider";

export const BeforeAfter = () => {
  return (
    <Container>
      <TitleBlock>
        <Title $fs={WINDOW_WIDTH > 768 ? 32 : 24} $mb={1}>
          before & after
        </Title>
        <TextWrapper>
          <BeforeAfterText
            width={WINDOW_WIDTH > 768 ? 402 : 143}
            height={WINDOW_WIDTH > 768 ? 26 : 60}
            mobile={WINDOW_WIDTH < 768}
          />
        </TextWrapper>
      </TitleBlock>
      {WINDOW_WIDTH < 768 ? (
        <CustomSlider />
      ) : (
        <CardsWrapper>
          {cards.map((card) => (
            <Card key={card.before}>
              <InnerGradient />
              {card.vertical ? (
                <VerticalPhotos
                  srcBefore={card.before}
                  altBefore={card.altBefore}
                  srcAfter={card.after}
                  altAfter={card.altAfter}
                />
              ) : (
                <HorizontalPhotos
                  srcBefore={card.before}
                  altBefore={card.altBefore}
                  srcAfter={card.after}
                  altAfter={card.altAfter}
                />
              )}
              <AllYouCanBecome>#AllYouCanBecome</AllYouCanBecome>
            </Card>
          ))}
        </CardsWrapper>
      )}
    </Container>
  );
};

export const VerticalPhotos = ({
  srcBefore,
  altBefore,
  srcAfter,
  altAfter,
}) => {
  return (
    <CardWrapper>
      <BeforeVerticalBlock>
        <StyledVerticalImage src={srcBefore} alt={altBefore} />
        <Button>before</Button>
      </BeforeVerticalBlock>
      <AfterVerticalBlock>
        <Button>after</Button>
        <StyledVerticalImage src={srcAfter} alt={altAfter} />
      </AfterVerticalBlock>
    </CardWrapper>
  );
};

export const HorizontalPhotos = ({
  srcBefore,
  altBefore,
  srcAfter,
  altAfter,
}) => {
  return (
    <HorizontalCardWrapper>
      <BeforeHorizontalImg>
        <Button>before</Button>
        <StyledHorizontalImage src={srcBefore} alt={altBefore} />
      </BeforeHorizontalImg>
      <AfterHorizontalImg>
        <StyledHorizontalImage src={srcAfter} alt={altAfter} />
        <Button>after</Button>
      </AfterHorizontalImg>
    </HorizontalCardWrapper>
  );
};
