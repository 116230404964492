import Before1 from "./before1.png";
import After1 from "./after1.png";
import Before2 from "./before2.png";
import After2 from "./after2.png";
import Before3 from "./before3.png";
import After3 from "./after3.png";
import Before4 from "./before4.png";
import After4 from "./after4.png";
import Before5 from "./before5.png";
import After5 from "./after5.png";
import Before6 from "./before6.png";
import After6 from "./after6.png";
import Before7 from "./before7.png";
import After7 from "./after7.png";
import Before8 from "./before8.png";
import After8 from "./after8.png";
import Before9 from "./before9.png";
import After9 from "./after9.png";
import Before10 from "./before10.png";
import After10 from "./after10.png";
import Before11 from "./before11.png";
import After11 from "./after11.png";
import Before12 from "./before12.png";
import After12 from "./after12.png";
import Before13 from "./before13.png";
import After13 from "./after13.png";
import Before14 from "./before14.png";
import After14 from "./after14.png";
import Before15 from "./before15.png";
import After15 from "./after15.png";
import Mockups from "./mainMockups.png";
import Method1 from "./Method1.png";
import Method2 from "./Method2.png";
import Method3 from "./Method3.png";
import ModalDesktopImage from "./modalDesktopImage.png";
import ModalMobileImage from "./modalMobileImage.png";
import DropUser1 from "./drop_user_1.jpeg";
import DropUser2 from "./drop_user_2.jpeg";
import DropUser3 from "./drop_user_3.jpeg";

const images = {
  Before1,
  After1,
  Before2,
  After2,
  Before3,
  After3,
  Before4,
  After4,
  Before5,
  After5,
  Before6,
  After6,
  Before7,
  After7,
  Before8,
  After8,
  Before9,
  After9,
  Before10,
  After10,
  Before11,
  After11,
  Before12,
  After12,
  Before13,
  After13,
  Before14,
  After14,
  Before15,
  After15,
  Mockups,
  Method1,
  Method2,
  Method3,
  ModalDesktopImage,
  ModalMobileImage,
  DropUser1,
  DropUser2,
  DropUser3,
};

export default images;
