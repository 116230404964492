import styled from "styled-components";

import { PADDING } from "../../lib/constants";
import { colors } from "../../UIKit";

export const Container = styled.div`
  display: flex;
  padding: 96px 0px;
  background-color: ${colors.bg};
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  margin: 0 auto;
  padding-bottom: 64px;
`;

export const ExpectationItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExpectationTitleWrapper = styled.div`
  display: flex;
  gap: ${PADDING}px;
  padding: 40px 0;
`;

export const SliderWrapper = styled.div`
  padding: 0 0 0 ${PADDING}px;
  position: relative;
`;

export const PagingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

export const PagingItemsWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const PagingItem = styled.li`
  width: 37px;
  height: 2px;
  background-color: ${(props) =>
    props.$active ? colors.primary[500] : colors.gray[100]};
  margin: 0 5px;
`;
