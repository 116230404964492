import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./fonts.css";
import "./index.css";
import { Footer, Header, MobileFooter, MobileHeader } from "./components";
import { WINDOW_WIDTH } from "./lib/constants";
import { ScrollToTopOnRouteChange } from "./components/ScrollToTopOnRouteChange";
import ScrollToHashElement from "./components/header/ScrollToHashElement";
import { Landing } from "./components/Landing";
import { PopUpContainer } from "./components/popUp/PopUpContainer";
import { DropUser, PrivacyPolicy, TermsAndConditions } from "./pages";

export const App = () => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmContent, setShowConfirmContent] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <BrowserRouter>
      <ScrollToHashElement />
      <PopUpContainer
        showModal={showModal}
        handleClose={handleClose}
        showConfirmContent={showConfirmContent}
        setShowConfirmContent={setShowConfirmContent}
      />
      {WINDOW_WIDTH > 768 ? <Header /> : <MobileHeader />}
      <ScrollToTopOnRouteChange />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/drop-user" element={<DropUser />} />
      </Routes>
      {WINDOW_WIDTH > 768 ? <Footer /> : <MobileFooter />}
    </BrowserRouter>
  );
};
