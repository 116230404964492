import React from "react";

import {
  PolicyItemWrapper,
  PolicySubtitle,
  PolicyText,
  PolicyTitle,
  StyledList,
} from "../styled";

export const GDPR = () => {
  return (
    <PolicyItemWrapper id="gdpr">
      <PolicyTitle>GDPR Privacy </PolicyTitle>
      <PolicySubtitle>
        Legal Basis for Processing Personal Data under GDPR
      </PolicySubtitle>
      <PolicyText>
        We may process Personal Data under the following conditions:
        <StyledList>
          <li>
            <b>Consent: </b>
            You have given Your consent for processing Personal Data for one or
            more specific purposes. For more information on the privacy
            practices of Google, please visit the Google Privacy & Terms web
            page:
          </li>
          <li>
            <b>Performance of a contract: </b> Provision of Personal Data is
            necessary for the performance of an agreement with You and/or for
            any pre-contractual obligations thereof.
          </li>
          <li>
            <b>Legal obligations: </b>
            Processing Personal Data is necessary for compliance with a legal
            obligation to which the Company is subject.
          </li>
          <li>
            <b>Vital interests: </b>
            Processing Personal Data is necessary in order to protect Your vital
            interests or of another natural person.
          </li>
          <li>
            <b>Public interests: </b>
            Processing Personal Data is related to a task that is carried out in
            the public interest or in the exercise of official authority vested
            in the Company.
          </li>
          <li>
            <b>Legitimate interests: </b>
            Processing Personal Data is necessary for the purposes of the
            legitimate interests pursued by the Company.
          </li>
        </StyledList>
        <br />
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </PolicyText>

      <PolicySubtitle>Your Rights under the GDPR </PolicySubtitle>
      <PolicyText>
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights. You have the right
        under this Privacy Policy, and by law if You are within the EU, to:
        <StyledList>
          <li>
            <b>Request access to Your Personal Data. </b> The right to access,
            update or delete the information We have on You. Whenever made
            possible, you can access, update or request deletion of Your
            Personal Data directly within Your account settings section. If you
            are unable to perform these actions yourself, please contact Us to
            assist You. This also enables You to receive a copy of the Personal
            Data We hold about You. Request correction of the Personal Data that
            We hold about You.
          </li>
          <li>
            <b>
              Request correction of the Personal Data that We hold about You.
            </b>{" "}
            You have the right to have any incomplete or inaccurate information
            We hold about You corrected.
          </li>
          <li>
            <b>Object to processing of Your Personal Data. </b>
            This right exists where We are relying on a legitimate interest as
            the legal basis for Our processing and there is something about Your
            particular situation, which makes You want to object to our
            processing of Your Personal Data on this ground. You also have the
            right to object where We are processing Your Personal Data for
            direct marketing purposes.
          </li>
          <li>
            <b>Request erasure of Your Personal Data. </b>
            You have the right to ask Us to delete or remove Personal Data when
            there is no good reason for Us to continue processing it.
          </li>
          <li>
            <b>Request the transfer of Your Personal Data. </b>
            We will provide to You, or to a third-party You have chosen, Your
            Personal Data in a structured, commonly used, machine-readable
            format. Please note that this right only applies to automated
            information which You initially provided consent for Us to use or
            where We used the information to perform a contract with You.
          </li>
          <li>
            <b>Withdraw Your consent. </b> You have the right to withdraw Your
            consent on using your Personal Data. If You withdraw Your consent,
            We may not be able to provide You with access to certain specific
            functionalities of the Service.
          </li>
        </StyledList>
      </PolicyText>

      <PolicySubtitle>
        Exercising of Your GDPR Data Protection Rights
      </PolicySubtitle>
      <PolicyText>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible. You have the
        right to complain to a Data Protection Authority about Our collection
        and use of Your Personal Data. For more information, if You are in the
        European Economic Area (EEA), please contact Your local data protection
        authority in the EEA.
      </PolicyText>
    </PolicyItemWrapper>
  );
};
