import React from "react";
import {
  PolicyItemWrapper,
  PolicySubtitle,
  PolicyText,
  PolicyTitle,
  StyledLink,
  StyledList,
} from "../styled";

export const InterpretationAndDefinitions = () => {
  return (
    <PolicyItemWrapper id="interpretation">
      <PolicyTitle>Interpretation and Definitions</PolicyTitle>
      <PolicySubtitle> 1. Interpretation</PolicySubtitle>
      <PolicyText>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </PolicyText>
      <PolicySubtitle> 2. Definitions</PolicySubtitle>
      <PolicyText>
        For the purposes of this Privacy Policy:
        <StyledList>
          <li>
            <b>Account</b> means a unique account created for You to access our
            Service or parts of our Service.
          </li>
          <li>
            <b>Affiliate</b> means an entity that controls, is controlled by or
            is under common control with a party, where "control" means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
          </li>
          <li>
            <b>Application</b> refers to All You Can Face, the software program
            provided by the Company.
          </li>
          <li>
            <b>Business,</b> for the purpose of CCPA/CPRA, refers to the Company
            as the legal entity that collects Consumers' personal information
            and determines the purposes and means of the processing of
            Consumers' personal information, or on behalf of which such
            information is collected and that alone, or jointly with others,
            determines the purposes and means of the processing of consumers'
            personal information, that does business in the State of California.
          </li>
          <li>
            <b>CCPA</b> and/or <b>BCPRA</b> refers to the California Consumer
            Privacy Act (the "CCPA") as amended by the California Privacy Rights
            Act of 2020 (the "CPRA").
          </li>
          <li>
            <b>Company</b> (referred to as either "the Company", "We", "Us" or
            "Our" in this Agreement) refers to Unified Notion Agency UG,
            Pappelallee 3, 10437 Berlin.
          </li>
          <li>
            For the purpose of the GDPR, the Company is the Data Controller.
          </li>
          <li>
            <b>Consumer, </b> for the purpose of the CCPA/CPRA, means a natural
            person who is a California resident. A resident, as defined in the
            law, includes (1) every individual who is in the USA for other than
            a temporary or transitory purpose, and (2) every individual who is
            domiciled in the USA who is outside the USA for a temporary or
            transitory purpose.
          </li>
          <li>
            <b>Country </b> refers to: Berlin, Germany
          </li>
          <li>
            <b>Data Controller, </b> for the purposes of the GDPR (General Data
            Protection Regulation), refers to the Company as the legal person
            which alone or jointly with others determines the purposes and means
            of the processing of Personal Data.
          </li>
          <li>
            <b>Device </b> means any device that can access the Service such as
            a computer, a cellphone or a digital tablet.
          </li>
          <li>
            <b>Do Not Track </b> (DNT) is a concept that has been promoted by US
            regulatory authorities, in particular the U.S. Federal Trade
            Commission (FTC), for the Internet industry to develop and implement
            a mechanism for allowing internet users to control the tracking of
            their online activities across websites.
          </li>
          <li>
            <b>Facebook Fan Page </b> is a public profile named All You Can Face
            specifically created by the Company on the Facebook social network,
            accessible from
            <StyledLink
              href="https://www.facebook.com/allyoucanface"
              rel="noopener noreferrer"
              target="_blank"
            >
              https:// www.facebook.com/allyoucanface
            </StyledLink>
          </li>
          <li>
            <b>GDPR </b> refers to EU General Data Protection Regulation.
          </li>
          <li>
            <b>Personal Data </b> is any information that relates to an
            identified or identifiable individual.
          </li>
          <li>
            For the purposes of GDPR, Personal Data means any information
            relating to You such as a name, an identification number, location
            data, online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity.
          </li>
          <li>
            For the purposes of the CCPA/CPRA, Personal Data means any
            information that identifies, relates to, describes or is capable of
            being associated with, or could reasonably be linked, directly or
            indirectly, with You.
          </li>
          <li>
            <b>Service</b> refers to the Application.
          </li>
          <li>
            <b> Service Provider</b> means any natural or legal person who
            processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used. For the purpose of the
            GDPR, Service Providers are considered Data Processors.
          </li>
          <li>
            <b> Third-party Social Media Service</b> refers to any website or
            any social network website through which a User can log in or create
            an account to use the Service.
          </li>
          <li>
            <b> Usage Data </b> refers to data collected automatically, either
            generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </li>
          <li>
            <b> You</b> means the individual accessing or using the Service, or
            the company, or other legal entity on behalf of which such
            individual is accessing or using the Service, as applicable.
          </li>
          <li>
            Under GDPR, You can be referred to as the Data Subject or as the
            User as you are the individual using the Service.
          </li>
        </StyledList>
      </PolicyText>
    </PolicyItemWrapper>
  );
};
