import React from "react";

import { WINDOW_WIDTH } from "../../lib/constants";

import {
  Asos,
  BeautyBay,
  Byrdie,
  Cosmopolitan,
  Elle,
  Glamour,
  Glossy,
  Lofficiel,
} from "../../UIKit";
import { Container, LogosList, LogosWrapper } from "./styled";

export const Logos = () => {
  const logosList = (
    <LogosList>
      <Byrdie width={80} height={12} />
      <Cosmopolitan width={125} height={22} />
      <Elle width={65} height={22} />
      <Glamour width={97} height={22} />
      <Lofficiel width={95} height={22} />
      <Asos width={71} height={20} />
      <BeautyBay width={57} height={20} />
      <Glossy width={100} height={12} />
    </LogosList>
  );

  return (
    <Container>
      <LogosWrapper>
        {logosList}
        {WINDOW_WIDTH < 768 && (
          <>
            {logosList}
            {logosList}
          </>
        )}
      </LogosWrapper>
    </Container>
  );
};
