import React from "react";

import { EMAIL, MAIN_SITE_URL } from "../../lib/constants";
import { termsAndConditionsData, termsDescription } from "./data";
import {
  Container,
  DocumentUpdated,
  LinkItem,
  LinksWrapper,
  StyledLink,
  StyledSubtitle,
  StyledTitle,
  TermsDescription,
  TermText,
  TermTitle,
  TermWrapper,
} from "./styled";

export const TermsAndConditions = () => {
  return (
    <Container>
      <StyledTitle>Terms and conditions</StyledTitle>
      <StyledSubtitle>Table of contents</StyledSubtitle>
      <LinksWrapper>
        {termsAndConditionsData.map((term, i) => (
          <LinkItem key={i}>
            <StyledLink
              href={`/terms-and-conditions#${term.title.toLowerCase()}`}
            >
              {term.title}
            </StyledLink>
          </LinkItem>
        ))}
      </LinksWrapper>
      <TermsDescription>{termsDescription}</TermsDescription>
      {termsAndConditionsData.map((term, i) => (
        <TermWrapper key={i} id={term.title.toLowerCase()}>
          <TermTitle>{term.title}</TermTitle>
          <TermText>{term.text}</TermText>
        </TermWrapper>
      ))}
      <StyledLink
        href={MAIN_SITE_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {MAIN_SITE_URL}
      </StyledLink>
      <StyledLink
        href={`mailto:${EMAIL}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {EMAIL}
      </StyledLink>
      <DocumentUpdated>
        This document was last updated on June 25, 2023
      </DocumentUpdated>
    </Container>
  );
};
