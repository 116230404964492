import React from "react";

import {
  colors,
  GooglePlay,
  AppleStore,
  Instagram,
  Logo,
  TikTok,
  Youtube,
} from "../../UIKit";
import { SocialLinks } from "../header/styled";
import { FooterNavigation } from "./FooterNavigation";
import {
  Container,
  ContentWrapper,
  CopyrightCompany,
  CopyrightDeveloped,
  CopyrightWrapper,
  DownloadButtons,
  NavTitle,
  Social,
} from "./styled";

export const Footer = () => {
  return (
    <Container>
      <ContentWrapper>
        <Logo width={120} height={120} color={colors.base.white} />
        <FooterNavigation />
        <Social>
          <NavTitle>SOCIAL MEDIA</NavTitle>
          <SocialLinks>
            <Instagram width={24} height={24} color={colors.base.white} />
            <TikTok width={24} height={24} color={colors.base.white} />
            <Youtube width={24} height={24} color={colors.base.white} />
          </SocialLinks>
        </Social>
        <DownloadButtons>
          <AppleStore width={112} height={32} color={colors.base.white} />
          <GooglePlay width={112} height={32} color={colors.base.white} />
        </DownloadButtons>
      </ContentWrapper>
      <CopyrightWrapper>
        <CopyrightCompany>© ALL YOU CAN FACE, 2023</CopyrightCompany>
        <CopyrightDeveloped>
          DESIGNED & DEVELOPED BY MILESTEP
        </CopyrightDeveloped>
      </CopyrightWrapper>
    </Container>
  );
};
