export const faqData = [
  {
    question: "What exactly is facial exercise?",
    answer:
      "It is important to understand that facial exercises differ from facial massages. The intensity of a facial workout is way much stronger. In this program, you will learn how to actively engage your muscles. This leads to muscle strength and elasticity which makes your face and skin look smooth and toned. If you commit regularly to the exercises you will be able to sustain your youthful appearance for longer.",
  },
  {
    question: "What are we working with?",
    answer:
      "We have more than 50 muscles on our faces, which are attached directly to the skin and we can exercise all of them. The special thing about the muscles in your face is that they are all connected directly to the skin – we will be working those muscles and it is important to understand that once you work those muscles they gain more volume which gives your skin a place to lay on, smoothing it in the process. No other tools are needed.",
  },
  {
    question: "How often do we have to practice until we see results?",
    answer:
      "Some people see results right away, even after one practice! But most people will see the first small results after approximately two to three weeks. Please note that measuring facial changes is very different from measuring bodily changes. The results seen on the face are much subtler and appear slowly over time. For this reason, we highly recommend taking “before” and “after” photos to keep yourself motivated.",
  },
  {
    question: "The Kickstart?",
    answer:
      "– Change the way you approach your skin and face! Learn techniques for life: Spend 10min daily and see yourself glow up. Unlock every day a new workout video + bonus material to become the best version of yourself naturally. Our goal is to make you achieve the best results possible and we’ll support you in every step of your journey.",
  },
  {
    question: "Will I get wrinkles from practising regularly?",
    answer:
      "No – some people think that moving facial muscles will cause more wrinkles. While the wrong repetitive kinds of movements can cause unwanted wrinkles, moving them in the right way can prevent and even reduce them. The All You Can Face routine is a holistic approach to your face this is why it is important to follow the sequence of the course and rest in between workouts. To make sure you are using the right muscles and moving them correctly, practice in front of a mirror until you are able to do the movements correctly. It is very important to avoid wrinkling other parts of your face while doing certain poses, this is explained in more detail in the course.",
  },
  {
    question: "Why not Botox?",
    answer:
      "Botox uses the theory of freezing the facial muscles. While it may be effective, it is a temporary solution because Botox injections will wear off within 3 to 6 months. Once muscles are frozen they cannot be used and therefore regress. After using botox for a longer period of time your facial muscles are suppressed, resulting in uneven facial expressions and asymmetry. Of course, everyone should make their own choices and we are definitely not here to judge but you should be aware of potential side effects of Botox, such as tightness of the affected areas, headaches, anxiety, and depression.",
  },
  {
    question:
      "I've had a facelift and/or botox. Can I still practice the facial exercises?",
    answer:
      "Yes – the beauty of the All You Can Face program is that it can be practised in combination with other facial treatments. However, since Botox suppresses your facial muscle movements it can be hard to move your facial muscles right after the treatment. If you feel extreme discomfort, stop the exercise and consult your physician. When you practice, make sure your face is well hydrated to avoid unnecessary damage to your skin’s surface. Just imagine smiling with chapped lips; moving your lips that way only makes it worse.",
  },
  {
    question: "How does the course work?",
    answer:
      "From the time you start the course you will exercise your entire face – starting from your neck all the way up to your forehead: each day has its individual, dedicated 10min workout routine & new exercises are introduced regularly to the program. First, you will start with the basics & then work your way up to the more complex exercises which for example combat facial asymmetry. You will be able to unlock a new video every 24 hours. To see the best results we recommend you do each video 1-2 times per day, preferably in the morning or evening.",
  },
  {
    question: "What are the Payment methods?",
    answer: "For the moment we accept Credit Card and Paypal Payments.",
  },
  {
    question: "Can I access the program from any device?",
    answer:
      "You are able to download the AllYouCanFace App from the App and Play Store to access the program from any mobile device.",
  },
  {
    question: "When do videos unlock?",
    answer:
      "You are able to set the time and date on when you would like to access the program during the registration process. Based on your choices, you will able to unlock a new workout sequence earliest every 24h after unlocking your last program use.",
  },
  {
    question: "Is there a way to get feedback or support?",
    answer:
      "Our All You Can Face team is always there for you to answer any questions regarding our Face Workout program. If you have any technical issues with accessing your videos or any other concerns regarding the exercises, we will be happy to help you solve them. The best way is to connect with us via our Support Ticket System on your profile within the App. We are always trying to improve ourselves and our program so we are always happy to get in touch and encourage you to share your feedback with us.",
  },
  {
    question: "How do billing and recurring payments work?",
    answer:
      "You can choose between 3 different billing cycles. Weekly, monthly  or annual. Depending on your membership you will be automatically billed weekly, monthly or on annual basis if you do not cancel your membership before the next billing cycle starts.",
  },
  {
    question: "Can I cancel at any time?",
    answer:
      "Yes, you can cancel at any moment of your subscription. Just go to your profile and click on 'My Subscription' where you will be able to manage your membership. Afterwards, your course access is granted until your subscription expires.",
  },
  {
    question: "What is the refund policy?",
    answer:
      "We do not offer refunds (gift cards included), since it is a digital product, but we will do our best to give you the best experience and support possible.",
  },
  {
    question: "Will I be able to access the program once I cancel?",
    answer:
      "Only actively subscribed course members will be able to participate in the program and have access to the content provided. After cancellation, you can make use of your membership until your subscription expires.",
  },
];
