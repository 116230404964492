import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { oldWebsite } from "../../lib/constants";
import { StyledHashLink, Text } from "../../pages/commonStyled";
import {
  AppleStore,
  Burger,
  Cancel,
  colors,
  fontFamily,
  GooglePlay,
  Instagram,
  Logo,
  TikTok,
  TriangleArrow,
  Youtube,
} from "../../UIKit";
import { navPages } from "../data";
import {
  Container,
  NavLinks,
  Wrapper,
  NavLink,
  NavWrapper,
  SocialBlock,
  DownloadButtonsWrapper,
  ButtonsWrapper,
  OldSiteButton,
  BurgerInner,
} from "./mobileStyled";
import { SocialLinks } from "./styled";

export const MobileHeader = () => {
  const location = useLocation();
  const bgColor = location.pathname === "/" ? colors.bg : colors.base.white;
  const [opened, setOpened] = useState(false);

  const onHandleClick = () => {
    setOpened(!opened);
  };

  const mobileMenuButton = !opened ? (
    <Burger width={24} height={24} />
  ) : (
    <Cancel width={24} height={24} />
  );

  const handleNavigate = () => {
    window.open(oldWebsite, "_blank");
  };

  return (
    <Container $bgColor={bgColor}>
      <Wrapper>
        <Logo width={40} height={40} />
        <div onClick={onHandleClick}>{mobileMenuButton}</div>
      </Wrapper>
      {opened && (
        <BurgerInner $bgColor={bgColor}>
          <NavWrapper>
            <Text
              $ff={fontFamily.redHat.bold}
              $fsz={15}
              $tt="uppercase"
              $align="left"
            >
              Navigation
            </Text>
            <NavLinks>
              {navPages.map((item, index) => (
                <NavLink key={index}>
                  <StyledHashLink to={item.page}>{item.name}</StyledHashLink>
                </NavLink>
              ))}
            </NavLinks>
          </NavWrapper>
          <SocialBlock>
            <Text
              $ff={fontFamily.redHat.bold}
              $fsz={15}
              $tt="uppercase"
              $align="left"
            >
              SOCIAL MEDIA
            </Text>
            <SocialLinks>
              <Instagram width={24} height={24} />
              <TikTok width={24} height={24} />
              <Youtube width={24} height={24} />
            </SocialLinks>
          </SocialBlock>
          <OldSiteButton onClick={handleNavigate}>
            <TriangleArrow width={20} height={20} />
            <div>back to old version</div>
          </OldSiteButton>
          <DownloadButtonsWrapper>
            <Text
              $ff={fontFamily.redHat.bold}
              $fsz={15}
              $tt="uppercase"
              $align="left"
            >
              GET THE APP
            </Text>
            <ButtonsWrapper>
              <AppleStore width={112} height={32} />
              <GooglePlay width={112} height={32} />
            </ButtonsWrapper>
          </DownloadButtonsWrapper>
        </BurgerInner>
      )}
    </Container>
  );
};
