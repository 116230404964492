import styled from "styled-components";

import { colors } from "../../UIKit";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 56px 319px 30px 380px;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 32px 20px 34px 20px;
  }
`;

export const Description = styled.div`
  color: ${colors.base.black};
  font-family: Jost;
  font-size: 16px;
  font-weight: 300;
  line-height: 129.4%;
  padding-top: 30px;
`;

export const PolicyTitle = styled.h2`
  color: ${colors.base.black};
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 30px;
`;

export const PolicySubtitle = styled.h3`
  color: ${colors.base.black};
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 5px;
`;

export const PolicyItemWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const PolicyText = styled(Description)`
  padding: 5px 0;
`;

export const StyledList = styled.ul`
  li {
    position: relative;
    padding-left: 15px;

    &:before {
      content: "·";
      font-size: 30px;
      position: absolute;
      left: 0;
      top: 7px;
      transform: translateY(-50%);
    }
  }
`;

export const StyledLink = styled.a`
  word-break: break-word;
  padding: 0 4px;
  cursor: pointer;
  text-transform: none;
`;
