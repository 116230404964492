import React from "react";
import Slider from "react-slick";

import { HorizontalPhotos, VerticalPhotos } from "./BeforeAfter";
import { cards } from "./data";
import { AllYouCanBecome, Card, InnerGradient } from "./styled";
import { SliderArrow } from "../SliderArrow";

export const CustomSlider = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
  };

  return (
    <Slider {...settings}>
      {cards.map((card) => (
        <Card key={card.before}>
          <InnerGradient />
          {card.vertical ? (
            <VerticalPhotos
              srcBefore={card.before}
              altBefore={card.altBefore}
              srcAfter={card.after}
              altAfter={card.altAfter}
            />
          ) : (
            <HorizontalPhotos
              srcBefore={card.before}
              altBefore={card.altBefore}
              srcAfter={card.after}
              altAfter={card.altAfter}
            />
          )}
          <AllYouCanBecome>#AllYouCanBecome</AllYouCanBecome>
        </Card>
      ))}
    </Slider>
  );
};

const ArrowNext = (props) => {
  return <SliderArrow {...props} direction="next" />;
};

const ArrowPrev = (props) => {
  return <SliderArrow {...props} direction="back" />;
};
