import styled from "styled-components";

import { colors, fontFamily } from "../../UIKit";

export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 20;
`;

export const ModalContent = styled.div`
  display: flex;
  position: absolute;
  z-index: 20;
  background-color: ${colors.bg};
`;

export const Overlay = styled(Modal)`
  background: rgba(49, 49, 49, 0.9);
`;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: ${(props) => (props.$showConfirmContent ? "516px" : "700px")};
  height: ${(props) => (props.$showConfirmContent ? "289px" : "400px")};
  background-color: ${colors.base.white};
  padding-left: ${(props) => (props.$showConfirmContent ? "0px" : "21px")};

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    flex-direction: column;
    padding: ${(props) => (props.$showConfirmContent ? "0" : "0 20px")};
  }
`;

export const RightBlock = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const StyledInput = styled.input`
  padding: 16px 14px 8px 14px;
  width: 100%;
  margin-bottom: 32px;
  border: none;
  border-bottom: 1px solid ${colors.gray[500]};

  &::placeholder {
    color: rgba(159, 159, 159, 0.40);
    font-size: 14px;
    font-family: ${fontFamily.redHat.regular};
  }

  &:focus {
    outline: none;
    background-color: ${colors.base.white};
`;

export const StyledButton = styled.input`
  width: 100%;
  background-color: ${colors.base.white};
  font-family: ${fontFamily.redHat.medium};
  border: 1px solid #5a5a5a;
  font-size: 12px;
  line-height: 132%;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 28px;
  border-radius: 2px;
  color: ${colors.base.black};
  height: 52px;
`;

export const StyledError = styled.p`
  color: red;
  font-size: 12px;
  font-weight: 500;
  margin-top: -30px;
`;

export const StyledCloseModal = styled.div`
  position: absolute;
  cursor: pointer;
  padding: 15px 25px 0 0;
  z-index: 10;

  @media (max-width: 768px) {
    display: flex;
    justify-items: flex-end;
    align-self: end;
    padding: 10px 0 0 0;
  }
`;

export const StyledGradient = styled.div`
  position: relative;
  top: 0px;
  right: 0px;

  @media (max-width: 768px) {
    top: 20px;
    right: 60px;
  }
`;

export const StyledImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const ContentWrapper = styled.div`
  text-align: center;
  padding: 45px 72px 72px 56px;
  border: 4px solid transparent;
  border-image: linear-gradient(
    135deg,
    #ffeba6 0%,
    #fffca5 16.15%,
    #ccffa5 31.25%,
    #a5ffc9 43.75%,
    #8bceff 56.25%,
    #dfc2ff 68.75%,
    #ffb4ea 83.85%,
    #ffa5c5 100%
  );
  border-image-slice: 1;

  @media (max-width: 768px) {
    padding: 30px 10px 51px 10px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CloseModal = styled.div`
  position: absolute;
  right: 14px;
  top: 9px;
  cursor: pointer;
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 35px;
  width: 300px;
  min-hight: 50px;
  padding: 15px;
  background-color: ${colors.base.white};
  border: 1px solid red;
  z-index: 30;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  border-radius: 5px;

  @media (max-width: 768px) {
    left: 20px;
    top: 20px;
  }
`;

export const CloseErrorNotifier = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;
