import React from "react";

import { PolicyItemWrapper, PolicyText, PolicyTitle } from "../styled";

export const YourCaliforniaPrivacy = () => {
  return (
    <PolicyItemWrapper id="your california privacy">
      <PolicyTitle>
        Your California Privacy Rights (California's Shine the Light law)
      </PolicyTitle>
      <PolicyText>
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes. If you'd like to request more information under the California
        Shine the Light law, and if You are a California resident, You can
        contact Us using the contact information provided below.
      </PolicyText>
    </PolicyItemWrapper>
  );
};
