import styled, { keyframes } from "styled-components";

import { INDENT_BETWEEN_PAGES, PADDING } from "../../lib/constants";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px ${INDENT_BETWEEN_PAGES}px;

  @media (max-width: 768px) {
    padding: 27px ${PADDING}px;
  }
`;

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const LogosWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  user-select: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const LogosList = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  wite-space: nowrap;

  @media (max-width: 768px) {
    animation: ${scroll} 20s linear infinite;
  }
`;
