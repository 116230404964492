import React from "react";

import { WINDOW_WIDTH } from "../../lib/constants";
import { colors, fontFamily, Plus } from "../../UIKit";
import { Button, Text, Title, TitleBlock } from "../commonStyled";
import { reviewsData } from "./data";
import { ReviewsSlider } from "./ReviewsSlider";
import {
  ButtonWrapper,
  Card,
  CardsList,
  Container,
  SliderWrapper,
} from "./styled";

export const Reviews = ({ visible, showMoreReviews, showButton }) => {
  return (
    <Container id="reviews">
      <TitleBlock width={WINDOW_WIDTH > 768 ? 798 : 327}>
        <Text
          $ff={fontFamily.redHat.semiBold}
          $fsz={15}
          $lh={121}
          $ls={2.4}
          $tt="uppercase"
          color={colors.primary[500]}
        >
          Reviews
        </Text>
        <Title $fs={WINDOW_WIDTH > 768 ? 32 : 24} $mt={12} $mb={24}>
          What others say
        </Title>
        <Text
          $lh={140}
          color={colors.gray[500]}
          $ff={fontFamily.redHat.regular}
          $fsz={17}
          $ls={0.17}
        >
          Is it really possible to achieve a noticeable result in such a short
          time? Check it out with our community members who are willing to share
          their progress.
        </Text>
      </TitleBlock>
      {WINDOW_WIDTH > 768 ? (
        <CardsList>
          {reviewsData.slice(0, visible).map((item, index) => (
            <Card key={index}>
              <img src={item.path} alt={item.alt} />
            </Card>
          ))}
          {showButton && (
            <ButtonWrapper onClick={showMoreReviews}>
              <Plus width={20} height={20} />
              <Button
                $align="left"
                $width={168}
                color={colors.gray[700]}
                $bg={colors.base.white}
              >
                more reviews
              </Button>
            </ButtonWrapper>
          )}
        </CardsList>
      ) : (
        <SliderWrapper>
          <ReviewsSlider />
        </SliderWrapper>
      )}
    </Container>
  );
};
