import React from "react";

import { PolicyItemWrapper, PolicyText, PolicyTitle } from "../styled";

export const DoNotTrackPolicy = () => {
  return (
    <PolicyItemWrapper id="do not track">
      <PolicyTitle>
        “Do Not Track" Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </PolicyTitle>
      <PolicyText>
        Our Service does not respond to Do Not Track signals. However, some
        third party websites do keep track of Your browsing activities. If You
        are visiting such websites, You can set Your preferences in Your web
        browser to inform websites that You do not want to be tracked. You can
        enable or disable DNT by visiting the preferences or settings page of
        Your web browser.
      </PolicyText>
    </PolicyItemWrapper>
  );
};
