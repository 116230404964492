import React from "react";

import {
  PolicyItemWrapper,
  PolicySubtitle,
  PolicyText,
  PolicyTitle,
  StyledLink,
} from "../styled";

export const FacebookFanPage = () => {
  return (
    <PolicyItemWrapper id="facebook fan page">
      <PolicyTitle>Facebook Fan Page </PolicyTitle>
      <PolicySubtitle>Data Controller for the Facebook Fan Page</PolicySubtitle>
      <PolicyText>
        The Company is the Data Controller of Your Personal Data collected while
        using the Service. As operator of the Facebook Fan Page
        <StyledLink
          href="https://www.facebook.com/allyoucanface"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/allyoucanface
        </StyledLink>
        , the Company and the operator of the social network Facebook are Joint
        Controllers. The Company has entered into agreements with Facebook that
        define the terms for use of the Facebook Fan Page, among other things.
        These terms are mostly based on the Facebook Terms of Service:
        <StyledLink
          href="https://www.facebook.com/terms.php"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/terms.php
        </StyledLink>
        Visit the Facebook Privacy Policy
        <StyledLink
          href="https://www.facebook.com/policy.php"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/policy.php
        </StyledLink>
        for more information about how Facebook manages Personal data or contact
        Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations,
        1601 Willow Road, Menlo Park, CA 94025, United States.
      </PolicyText>

      <PolicySubtitle>Facebook Insights</PolicySubtitle>
      <PolicyText>
        We use the Facebook Insights function in connection with the operation
        of the Facebook Fan Page and on the basis of the GDPR, in order to
        obtain anonymized statistical data about Our users. For this purpose,
        Facebook places a Cookie on the device of the user visiting Our Facebook
        Fan Page. Each Cookie contains a unique identifier code and remains
        active for a period of two years, except when it is deleted before the
        end of this period. Facebook receives, records and processes the
        information stored in the Cookie, especially when the user visits the
        Facebook services, services that are provided by other members of the
        Facebook Fan Page and services by other companies that use Facebook
        services. For more information on the privacy practices of Facebook,
        please visit Facebook Privacy Policy here:
        <StyledLink
          href="https://www.facebook.com/privacy/explanation"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/privacy/explanation
        </StyledLink>
      </PolicyText>
    </PolicyItemWrapper>
  );
};
