import React from "react";

import { PolicyItemWrapper, PolicyText, PolicyTitle } from "../styled";

export const RightsForMinorUsers = () => {
  return (
    <PolicyItemWrapper id="minor users rights">
      <PolicyTitle>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </PolicyTitle>
      <PolicyText>
        California Business and Professions Code Section 22581 allows California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted. To request removal of such data,
        and if You are a California resident, You can contact Us using the
        contact information provided below, and include the email address
        associated with Your account. Be aware that Your request does not
        guarantee complete or comprehensive removal of content or information
        posted online and that the law may not permit or require removal in
        certain circumstances.
      </PolicyText>
    </PolicyItemWrapper>
  );
};
