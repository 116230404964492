import React from "react";
import {
  PolicyItemWrapper,
  PolicySubtitle,
  PolicyText,
  PolicyTitle,
} from "../styled";

export const UsingPersonalData = () => {
  return (
    <PolicyItemWrapper id="using personal data">
      <PolicyTitle>TYPES OF DATA COLLECTED. USAGE DATA</PolicyTitle>
      <PolicyText>
        Usage Data is collected automatically when using the Service. Usage Data
        may include information such as Your Device's Internet Protocol address
        (e.g. IP address), browser type, browser version, the pages of our
        Service that You visit, the time and date of Your visit, the time spent
        on those pages, unique device identifiers and other diagnostic data.
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data. We may also collect information that Your browser sends
        whenever You visit our Service or when You access the Service by or
        through a mobile device.
      </PolicyText>

      <PolicyText>This data may include but is not limited to:</PolicyText>

      <PolicySubtitle>Contact Information:</PolicySubtitle>
      <PolicyText>
        We may collect and store contact information from your device, including
        but not limited to your address book, to facilitate social features and
        invite friends.
      </PolicyText>

      <PolicySubtitle>Personal Information:</PolicySubtitle>
      <PolicyText>
        This includes your name, surname, and other personal details provided
        during registration or while using our services. This information is
        used to personalize your experience and communicate with you. We collect
        images taken during the selfie progress tracking. However, these images
        will not be used or shared without direct permission. We prioritize user
        privacy and ensure that their images remain confidential unless
        expressly authorized by the user.
      </PolicyText>

      <PolicySubtitle>Location Data:</PolicySubtitle>
      <PolicyText>
        With your consent, we may collect location data to provide
        location-based services and improve our app's functionality.
      </PolicyText>

      <PolicySubtitle>Usage Information:</PolicySubtitle>
      <PolicyText>
        We gather data about how you use our app, including the content you
        view, features you access, and interactions with other users. This helps
        us improve our services and understand user preferences.
      </PolicyText>

      <PolicySubtitle>Device Information:</PolicySubtitle>
      <PolicyText>
        We may collect device-specific information, such as the type of device
        you're using, device settings, operating system, and unique identifiers.
        This helps us troubleshoot issues and provide optimal performance.
      </PolicyText>

      <PolicyText>
        By using All You Can Face, you consent to the collection and processing
        of this data as described in our privacy policy. We prioritize the
        security and confidentiality of your information and employ
        industry-standard measures to protect it. You can review our full
        privacy policy for more details on data usage, protection, and your
        rights as a user.
      </PolicyText>

      {/* <PolicySubtitle>1. Types of Data Collected. Personal Data</PolicySubtitle>
      <PolicyText>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </PolicyText>
      <PolicyText>
        <StyledList>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Usage Data</li>
        </StyledList>
      </PolicyText>
      <PolicySubtitle>2. Types of Data Collected. Usage Data</PolicySubtitle>
      <PolicyText>
        Usage Data is collected automatically when using the Service. Usage Data
        may include information such as Your Device's Internet Protocol address
        (e.g. IP address), browser type, browser version, the pages of our
        Service that You visit, the time and date of Your visit, the time spent
        on those pages, unique device identifiers and other diagnostic data.
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data. We may also collect information that Your browser sends
        whenever You visit our Service or when You access the Service by or
        through a mobile device.
      </PolicyText>

      <PolicySubtitle>
        3. Information from Third-Party Social Media Services
      </PolicySubtitle>
      <PolicyText>
        The Company allows You to create an account and log in to use the
        Service through the following Third-party Social Media Services:
      </PolicyText>
      <PolicyText>
        <StyledList>
          <li>Google</li>
          <li>Facebook</li>
          <li>Instagram</li>
          <li>Twitter</li>
          <li>Linkedin</li>
        </StyledList>
      </PolicyText>
      <PolicyText>
        If You decide to register through or otherwise grant us access to a
        Third-Party Social Media Service, We may collect Personal data that is
        already associated with Your Third-Party Social Media Service's account,
        such as Your name, Your email address, Your activities or Your contact
        list associated with that account. You may also have the option of
        sharing additional information with the Company through Your Third-Party
        Social Media Service's account. If You choose to provide such
        information and Personal Data, during registration or otherwise, You are
        giving the Company permission to use, share, and store it in a manner
        consistent with this Privacy Policy.
      </PolicyText>

      <PolicySubtitle>
        4. Information Collected while Using the Application
      </PolicySubtitle>
      <PolicyText>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:
        <StyledList>
          <li>Information regarding your location</li>
          <li>Information from your Device's phone book (contacts list) </li>
          <li>
            Pictures of your Face and other information from your Device's
            camera and photo library
          </li>
        </StyledList>
      </PolicyText>
      <PolicyText>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. Based on the face picture you upload we can
        recommend you certain face yoga exercises and make your experience
        customised to your own needs and goals. The information may be uploaded
        to the Company's servers and/or a Service Provider's server or it may be
        simply stored on Your device. You can enable or disable access to this
        information at any time, through Your Device settings.
      </PolicyText>

      <PolicySubtitle>5. Collection and Use of Facial Data</PolicySubtitle>
      <PolicyText>
        Our service provides a unique face yoga program, a part of which
        involves tracking user progress through facial photos. We collect and
        store facial data for two key reasons:
        <StyledList>
          <li>
            To allow users to track their own progress and see the tangible
            effects of our face yoga exercises over time.
          </li>
          <li>
            To provide personalised suggestions for improvement based on their
            progress.
          </li>
        </StyledList>
      </PolicyText>
      <PolicyText>
        Facial data is stored for a duration of 24 months from the date of the
        photograph. This period aligns with our typical program duration and
        allows for a comprehensive view of the user's progress over time. Rest
        assured, we do not store this data indefinitely; all facial data is
        automatically deleted from our servers 24 months after it was taken. We
        respect your privacy and do not share facial data with third parties.
        However, we do provide an option for users to share their progress
        photos on our social media platforms to inspire others. This is entirely
        optional and will only occur if explicit consent is given by the user
        via a clear, dedicated button within the app. Notwithstanding anything
        to the contrary in other Sections of this Privacy Policy in relation to
        the Personal Data, we do not:
        <StyledList>
          <li>
            collect, store, share or transfer the Face Data off the User
            devices;
          </li>
          <li>use the Face Data to identify any particular individual user;</li>
          <li>
            use the Face Data for authentication, advertising, or marketing
            purposes, or to otherwise target a user in a similar manner;
          </li>
          <li>
            use the Face Data to build a user profile, or otherwise attempt,
            facilitate, or encourage third parties to identify anonymous users
            or reconstruct user profiles based on the Face Data;
          </li>
          <li>
            transfer, share, sell, or otherwise provide the Face Data to
            advertising platforms, analytics providers, data brokers,
            information resellers or other such parties.
          </li>
        </StyledList>
      </PolicyText>
      <PolicyText>
        Our handling of your facial data is centered around your comfort and
        control. The sharing of face data is not mandatory to use our services.
        Users can choose not to share their photos during the onboarding process
        or by adjusting their privacy settings at any time.
      </PolicyText>

      <PolicySubtitle>6. Use of Your Personal Data</PolicySubtitle>
      <PolicyText>
        The Company may use Personal Data for the following purposes:
      </PolicyText>
      <PolicyText>
        <StyledList>
          <li>
            <b>To provide and maintain our Service, </b>
            including to monitor the usage of our Service. Also, we use the
            information to customise your experience.
          </li>
          <li>
            <b>To manage Your Account: </b>
            to manage Your registration as a user of the Service. The Personal
            Data You provide can give You access to different functionalities of
            the Service that are available to You as a registered user.
          </li>
          <li>
            <b>For the performance of a contract: </b>
            the development, compliance and undertaking of the purchase contract
            for the products, items or services You have purchased or of any
            other contract with Us through the Service.
          </li>
          <li>
            <b> To contact You: </b>
            To contact You by email, telephone calls, SMS, or other equivalent
            forms of electronic communication, such as a mobile application's
            push notifications regarding updates or informative communications
            related to the functionalities, products or contracted services,
            including the security updates, when necessary or reasonable for
            their implementation. To
          </li>
          <li>
            <b>To provide You </b>
            with news, special offers and general information about other goods,
            services and events which we offer that are similar to those that
            you have already purchased or enquired about unless You have opted
            not to receive such information.
          </li>
          <li>
            <b>To manage Your requests: </b> To attend and manage Your requests
            to Us.
          </li>
          <li>
            <b>To deliver targeted advertising to You:</b> We may use Your
            information to develop and display content and advertising (and work
            with third-party vendors who do so) tailored to Your interests
            and/or location and to measure its effectiveness.
          </li>
          <li>
            <b>For business transfers: </b> We may use Your information to
            evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </li>
          <li>
            <b>For other purposes: </b> We may use Your information for other
            purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </li>
        </StyledList>
      </PolicyText>
      <PolicyText>
        We may share Your personal information in the following situations:
        <StyledList>
          <li>
            <b>With Service Providers:</b>
            We may share Your personal information with Service Providers to
            monitor and analyze the use of our Service, to advertise on third
            party websites to You after You visited our Service, for payment
            processing, to contact You.
          </li>
          <li>
            <b>For business transfers: </b> We may share or transfer Your
            personal information in connection with, or during negotiations of,
            any merger, sale of Company assets, financing, or acquisition of all
            or a portion of Our business to another company.
          </li>
          <li>
            <b>With Affiliates: </b>
            We may share Your information with Our affiliates, in which case we
            will require those affiliates to honor this Privacy Policy.
            Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that
            are under common control with Us.
          </li>
          <li>
            <b>With business partners: </b> We may share Your information with
            Our business partners to offer You certain products, services or
            promotions.
          </li>
          <li>
            <b>With other users: </b> when You share personal information or
            otherwise interact in the public areas with other users, such
            information may be viewed by all users and may be publicly
            distributed outside. If You interact with other users or register
            through a ThirdParty Social Media Service, Your contacts on the
            Third-Party Social Media Service may see Your name, profile,
            pictures and description of Your activity. Similarly, other users
            will be able to view descriptions of Your activity, communicate with
            You and view Your profile.
          </li>
          <li>
            <b>With Your consent: </b> We may disclose Your personal information
            for any other purpose with Your consent.
          </li>
        </StyledList>
      </PolicyText>

      <PolicySubtitle>7. Retention of Your Personal Data</PolicySubtitle>
      <PolicyText>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies. The Company will also retain Usage Data
        for internal analysis purposes. Usage Data is generally retained for a
        shorter period of time, except when this data is used to strengthen the
        security or to improve the functionality of Our Service, or We are
        legally obligated to retain this data for longer time periods.
      </PolicyText>

      <PolicySubtitle>8. Transfer of Your Personal Data</PolicySubtitle>
      <PolicyText>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction. Your
        consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer. The Company will
        take all steps reasonably necessary to ensure that Your data is treated
        securely and in accordance with this Privacy Policy and no transfer of
        Your Personal Data will take place to an organization or a country
        unless there are adequate controls in place including the security of
        Your data and other personal information.
      </PolicyText>

      <PolicySubtitle>9. Delete Your Personal Data</PolicySubtitle>
      <PolicyText>
        You have the right to delete or request that We assist in deleting the
        Personal Data that We have collected about You. Our Service may give You
        the ability to delete certain information about You from within the
        Service. You may update, amend, or delete Your information at any time
        by signing in to Your Account, if you have one, and visiting the account
        settings section that allows you to manage Your personal information.
        You may also contact Us to request access to, correct, or delete any
        personal information that You have provided to Us. Please note, however,
        that We may need to retain certain information when we have a legal
        obligation or lawful basis to do so.
      </PolicyText>

      <PolicySubtitle>
        10. Disclosure of Your Personal Data. Business Transactions
      </PolicySubtitle>
      <PolicyText>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy
      </PolicyText>

      <PolicySubtitle>
        11. Disclosure of Your Personal Data. Law enforcement
      </PolicySubtitle>
      <PolicyText>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </PolicyText>

      <PolicySubtitle>
        12. Disclosure of Your Personal Data. Other legal requirements
      </PolicySubtitle>
      <PolicyText>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
        <StyledList>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </StyledList>
      </PolicyText>

      <PolicySubtitle>13. Security of Your Personal Data</PolicySubtitle>
      <PolicyText>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </PolicyText> */}
    </PolicyItemWrapper>
  );
};
