import styled from "styled-components";

import { MOBILE_DEFAULT_PADDING, PADDING } from "../../lib/constants";
import { colors } from "../../UIKit";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.bg};
  padding: 0 ${PADDING}px ${MOBILE_DEFAULT_PADDING}px ${PADDING}px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledGradient = styled.div`
  position: relative;
`;

export const Mockups = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
