import React from "react";

import { colors } from "../UIKit";
import { LongSliderArrow } from "../UIKit/icons";

export const SliderArrow = ({
  className,
  currentSlide,
  onClick,
  slideCount,
  direction,
}) => {
  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === slideCount - 1;
  const isNextArrow = direction === "next";
  const color =
    (isFirstSlide && !isNextArrow) || (isLastSlide && isNextArrow)
      ? colors.gray[100]
      : colors.primary[500];

  const arrowStyles = {
    position: "absolute",
    top: -40,
    [isNextArrow ? "right" : "left"]: isNextArrow ? "8%" : "75%",
  };

  return (
    <div className={className} style={arrowStyles} onClick={onClick}>
      <LongSliderArrow
        width={24}
        height={24}
        direction={isNextArrow ? "next" : "back"}
        color={color}
      />
    </div>
  );
};
