import React from "react";

import { Text, Title, TitleBlock } from "../commonStyled";
import {
  Advice,
  AdviceText,
  AdviceTitle,
  AdviceWrapper,
  Container,
  ImageWrapper,
  LeftBlock,
  NumberWrapper,
  RightBlock,
  StyledUl,
} from "./styled";
import { adviceData } from "./data";
import { colors, fontFamily } from "../../UIKit";

export const Methods = ({ imagesData, imageIndex }) => {
  return (
    <Container id="methods">
      <LeftBlock>
        <TitleBlock width={360}>
          <Text
            $ff={fontFamily.redHat.semiBold}
            $fsz={15}
            $lh={121}
            $ls={2.4}
            $tt="uppercase"
            color={colors.primary[500]}
          >
            Methods
          </Text>
          <Title $align="left" $mt={12} $mb={40}>
            what you can expect
          </Title>
        </TitleBlock>

        <ImageWrapper>
          <img
            src={imagesData[imageIndex].path}
            width={322}
            height={658}
            alt="methodsImg.png"
          />
        </ImageWrapper>
      </LeftBlock>
      <RightBlock id="rightBlock">
        <StyledUl>
          {adviceData.map((item) => (
            <li key={item.title}>
              <AdviceWrapper>
                <NumberWrapper>{item.number}</NumberWrapper>
                <Advice>
                  <AdviceTitle>{item.title}</AdviceTitle>
                  <AdviceText>{item.text}</AdviceText>
                </Advice>
              </AdviceWrapper>
            </li>
          ))}
        </StyledUl>
      </RightBlock>
    </Container>
  );
};
