import React from "react";

import { EMAIL } from "../../../lib/constants";
import {
  PolicyItemWrapper,
  PolicyText,
  PolicyTitle,
  StyledLink,
} from "../styled";

export const ContactUs = () => {
  return (
    <PolicyItemWrapper id="contact us">
      <PolicyTitle>Contact Us</PolicyTitle>
      <PolicyText>
        If you have any questions about this Privacy Policy, You can contact us:
        <br />
        By email:
        <StyledLink
          href={`mailto:${EMAIL}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {EMAIL}
        </StyledLink>
      </PolicyText>
    </PolicyItemWrapper>
  );
};
