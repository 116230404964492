import React from "react";

import { WINDOW_WIDTH } from "../lib/constants";
import {
  About,
  FAQ,
  Main,
  Membership,
  MethodsContainer,
  MobileMain,
  ReviewsContainer,
} from "../pages";
import { MobileMethods } from "../pages/methods/MobileMethods";
import { BeforeAfter } from "./beforeAfter/BeforeAfter";
import { Logos } from "./logos/Logos";

export const Landing = () => {
  return (
    <>
      {WINDOW_WIDTH > 768 ? <Main /> : <MobileMain />}
      <Logos />
      <About />
      <BeforeAfter />
      {WINDOW_WIDTH > 768 ? <MethodsContainer /> : <MobileMethods />}
      <ReviewsContainer />
      <Membership />
      <FAQ />
    </>
  );
};
