import styled from "styled-components";

import { INDENT_BETWEEN_PAGES, PADDING } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";

export const Container = styled.div`
  padding-bottom: ${INDENT_BETWEEN_PAGES}px;

  @media (max-width: 768px) {
    padding: ${PADDING}px ${PADDING}px 96px ${PADDING}px;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  margin-bottom: 64px;

  @media (max-width: 768px) {
    max-width: 143px;
    margin-bottom: 30px;
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  @media (max-width: 768px) {
    position: relative;
    top: -45px;
    left: 7%;
    transform: none;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  gap: ${PADDING}px;
  padding: 0 ${INDENT_BETWEEN_PAGES}px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 36px;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: 100%;
    justify-content: space-around;
  }
`;

export const HorizontalCardWrapper = styled.div`
  display: flex;
  height: 87%;
  min-width: 384px;
  width: 100%;
  position: relative;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: unset;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 384px;
  height: 384px;
  padding: 56px 64px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.gray[100]};
  position: relative;

  @media (max-width: 768px) {
    padding: 36px;
  }
`;

export const StyledVerticalImage = styled.img`
  max-height: 80%;
  height: 100%;

  @media (max-width: 768px) {
    max-height: 50%;
  }
`;

export const StyledHorizontalImage = styled.img`
  max-width: 100%;
  width: 100%;
`;

const CommonStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

export const BeforeVerticalBlock = styled(CommonStyles)`
  align-items: center;
`;

export const BeforeHorizontalImg = styled(CommonStyles)`
  align-items: start;
`;

export const AfterVerticalBlock = styled(CommonStyles)`
  align-items: center;
`;

export const AfterHorizontalImg = styled(CommonStyles)`
  align-items: end;
`;

export const Button = styled.div`
  display: flex;
  width: 64px;
  height: 20px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border: 0.5px solid ${colors.base.black};
  border-radius: 2px;
  font-family: ${fontFamily.redHat.medium};
  font-size: 11px;
  line-height: 100%;
`;

export const AllYouCanBecome = styled.div`
  text-align: center;
  padding-top: 50px;
  font-family: ${fontFamily.redHat.regular};
  font-size: 11px;
  color: ${colors.gray[500]};
  line-height: 100%;
  position: relative;

  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

export const InnerGradient = styled.div`
  display: flex;
  margin: 0 auto;
  position: absolute;
  min-width: 70%;
  min-height: 70%;
  border-radius: 200px;
  background: var(
    --rainbow-rainbow-2,
    linear-gradient(
      135deg,
      #ffeba6 0%,
      #fffca5 16.15%,
      #ccffa5 31.25%,
      #a5ffc9 43.75%,
      #8bceff 56.25%,
      #dfc2ff 68.75%,
      #ffb4ea 83.85%,
      #ffa5c5 100%
    )
  );
  filter: blur(32px);
`;
