import React from "react";
import { WINDOW_WIDTH } from "../../lib/constants";

import { colors, fontFamily } from "../../UIKit";
import { Text, Title, TitleBlock } from "../commonStyled";
import { benefitsData } from "./data";
import {
  BenefitItem,
  BenefitsWrapper,
  BenefitText,
  BenefitTitle,
  Container,
  GradientWrapper,
} from "./styled";

export const About = () => {
  return (
    <Container id="about">
      <TitleBlock>
        <Text
          $ff={fontFamily.redHat.semiBold}
          $fsz={15}
          $lh={121}
          $ls={2.4}
          $tt="uppercase"
          color={colors.primary[500]}
        >
          About
        </Text>
        <Title
          $mt={12}
          $mb={WINDOW_WIDTH > 768 ? 80 : 64}
          $fs={WINDOW_WIDTH > 768 ? 32 : 24}
        >
          benefits face yoga can provide
        </Title>
      </TitleBlock>
      <BenefitsWrapper>
        {benefitsData.map((benefit, index) => (
          <BenefitItem key={index}>
            <GradientWrapper>{benefit.gradient}</GradientWrapper>
            <BenefitTitle>{benefit.title}</BenefitTitle>
            <BenefitText>{benefit.text}</BenefitText>
          </BenefitItem>
        ))}
      </BenefitsWrapper>
    </Container>
  );
};
