import styled from "styled-components";

import { INDENT_BETWEEN_PAGES, PADDING } from "../../lib/constants";
import { colors } from "../../UIKit";
import { Button, Text } from "../commonStyled";

export const Container = styled.div`
  display: flex;
  padding: ${INDENT_BETWEEN_PAGES}px;
  background-color: ${colors.bg};
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 96px ${PADDING}px;
  }
`;

export const SubscriptionList = styled.div`
  padding-top: 80px;
  display: flex;
  gap: ${PADDING}px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Subscription = styled.div`
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  position: relative;

  border: ${(props) =>
    props.$recommended ? `1px solid ${colors.primary[500]}` : "none"};

  @media (max-width: 768px) {
    border: ${(props) =>
      props.$recommended
        ? `1px solid ${colors.primary[500]}`
        : `1px solid ${colors.gray[100]}`};
  }
`;

export const StyledText = styled(Text)`
  border-bottom: 1px solid ${colors.primary[500]};
`;

export const Advantages = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 40px 0;
  min-height: 240px;
  height: 100%;
`;

export const Advantage = styled.li`
  position: relative;
  padding-left: 12px;

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${colors.primary[500]};
  }

  &:nth-child(2) {
    margin: 16px 0;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  left: 45%;
  top: 65px;
  z-index: 2;

  @media (max-width: 768px) {
    left: 0;
    top: 720px;
    border-radius: 8px 0px;
  }
`;
