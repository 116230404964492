import React from "react";

import { PolicyItemWrapper, PolicyText, PolicyTitle, StyledLink, StyledList } from "../styled";

export const DeletingFlow = () => {
  return (
    <PolicyItemWrapper id="deleting flow">
      <PolicyTitle>
        Deleting Your Profile and Data
      </PolicyTitle>
      <PolicyText>
        At <StyledLink href="allyoucanface.com">allyoucanface.com</StyledLink>, we value your privacy and want to ensure you have control over your personal information. 
        If you wish to delete your profile and all associated data, you can easily do so by following these steps: 
        <StyledList>
        <br />

          <li>Log in to your account.</li>
          <li>Navigate to your profile settings.</li>
          <li>Click on the “Delete My Profile” button.</li>
        </StyledList>
        <br />
        By clicking this button, your profile and all related data will be permanently deleted from our system. Please note that this action is irreversible, and once your data is deleted, it cannot be recovered. 
        If you encounter any issues or have questions about the deletion process, please contact our support team for assistance.
      </PolicyText>
    </PolicyItemWrapper>
  );
};
