import React from "react";

import { Text, Title } from "../../pages/commonStyled";
import { ClosePopUp, fontFamily, MainPageGradient } from "../../UIKit";
import {
  RightBlock,
  StyledButton,
  StyledCloseModal,
  StyledError,
  StyledGradient,
  StyledImage,
  StyledInput,
} from "./styled";
import images from "../../assets/images";
import { WINDOW_WIDTH } from "../../lib/constants";
import { ErrorNotifier } from "./ErrorNotifier";

export const SubscribeContent = ({
  register,
  errors,
  handleSubmit,
  onSubmit,
  handleClose,
  error,
  handleCloeErrorNotifier,
}) => {
  return (
    <>
      <div>
        <ErrorNotifier
          handleCloeErrorNotifier={handleCloeErrorNotifier}
          error={error}
        />
        <Title
          $fs={16}
          $mt={WINDOW_WIDTH > 768 ? 46 : 24}
          $mb={1}
          $align="left"
        >
          Free Face Yoga Guide
        </Title>
        <Text
          $lh={125}
          $ff={fontFamily.redHat.regular}
          $align="left"
          $fsz={14}
          $pb={WINDOW_WIDTH > 768 ? 42 : 16}
        >
          Learn how to improve dark circles, smooth out wrinkles & lift your
          face in just 10 min a day.
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledInput
            {...register("name", { required: true })}
            placeholder="Your Name"
          />
          <StyledError>{errors.name?.message}</StyledError>
          <StyledInput
            {...register("email", { required: true })}
            placeholder="Your Email"
          />
          <StyledError>{errors.email?.message}</StyledError>

          <StyledButton type="submit" value="Subscribe now" />
        </form>
      </div>
      <RightBlock>
        {WINDOW_WIDTH > 768 && (
          <StyledCloseModal onClick={handleClose}>
            <ClosePopUp width={12} height={23} />
          </StyledCloseModal>
        )}
        <StyledGradient>
          <MainPageGradient
            width={WINDOW_WIDTH > 768 ? 364 : 240}
            height={WINDOW_WIDTH > 768 ? 444 : 250}
          />
        </StyledGradient>
        {WINDOW_WIDTH > 768 ? (
          <StyledImage src={images.ModalDesktopImage} alt="img.pic" />
        ) : (
          <StyledImage src={images.ModalMobileImage} alt="img.pic" />
        )}
      </RightBlock>
    </>
  );
};
