import {
  AboutGradientFirst,
  AboutGradientSecond,
  AboutGradientThird,
} from "../../UIKit";

export const benefitsData = [
  {
    gradient: <AboutGradientFirst width={240} height={224} />,
    title: "BECOME MORE RADIANT & BEAUTIFUL",
    text: "Experience the power of face yoga to reduce a double chin, diminish dark circles, sculpt, lift, and enhance your skin tone. Embrace natural beauty, effortlessly.",
  },
  {
    gradient: <AboutGradientSecond width={216} height={224} />,
    title: "RELIEVE TENSION & REJUVENATE YOUR MOOD",
    text: "Release built-up tension in your face and reduce common discomforts like headaches. Rejuvenate not just your facial muscles but also your mood, leaving you feeling refreshed and energized.",
  },
  {
    gradient: <AboutGradientThird width={240} height={224} />,
    title: "MASTER YOUR COMMUNICATION & EXPRESSION",
    text: "Discover face yoga, gain awareness of your expressions, and unlock better communication. Express your true self with confidence.",
  },
];
