import React, { useState } from "react";

import { reviewsData } from "./data";
import { Reviews } from "./Reviews";

export const ReviewsContainer = () => {
  const [visible, setVisible] = useState(8);
  const totalReviews = reviewsData.length;
  const showButton = visible < totalReviews;

  const showMoreReviews = () => {
    setVisible((prevValue) => Math.min(prevValue + 8, totalReviews));
  };

  return (
    <Reviews
      showButton={showButton}
      visible={visible}
      showMoreReviews={showMoreReviews}
    />
  );
};
