import React from "react";

import images from "../../assets/images";
import { AppleStore, GooglePlay, MainPageGradient } from "../../UIKit";
import { Title } from "../commonStyled";
import {
  ButtonsWrapper,
  Container,
  LeftContent,
  Mockups,
  RightContent,
  StyledGradient,
  Subtitle,
} from "./styled";

export const Main = () => {
  return (
    <Container id="/">
      <LeftContent>
        <Title $align="left" $fs={38} $mb={12}>
          Revolutionize your Beauty & Wellness with Face Yoga
        </Title>
        <Subtitle>
          Unleash your beauty and self-confidence with a personalized workout
          program.
        </Subtitle>
        <ButtonsWrapper>
          <AppleStore width={200} height={56} />
          <GooglePlay width={200} height={56} />
        </ButtonsWrapper>
      </LeftContent>
      <RightContent>
        <StyledGradient>
          <MainPageGradient width={712} height={768} />
        </StyledGradient>
        <Mockups src={images.Mockups} alt="mockups.pic" />
      </RightContent>
    </Container>
  );
};
