import React from "react";

import { ClosePopUp } from "../../UIKit";
import {
  Container,
  Modal,
  ModalContent,
  Overlay,
  StyledCloseModal,
} from "./styled";
import { WINDOW_WIDTH } from "../../lib/constants";
import { SubscribeContent } from "./SubscribeContent";
import { ConfirmContent } from "./ConfirmContent";

export const PopUp = ({
  register,
  errors,
  handleSubmit,
  onSubmit,
  handleClose,
  showModal,
  showConfirmContent,
  error,
  handleCloeErrorNotifier,
}) => {
  return (
    <>
      {showModal && (
        <Modal>
          <Overlay />
          <ModalContent>
            <Container $showConfirmContent={showConfirmContent}>
              {WINDOW_WIDTH < 768 && !showConfirmContent && (
                <StyledCloseModal onClick={handleClose}>
                  <ClosePopUp width={12} height={23} />
                </StyledCloseModal>
              )}
              {!showConfirmContent ? (
                <SubscribeContent
                  register={register}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  handleClose={handleClose}
                  error={error}
                  handleCloeErrorNotifier={handleCloeErrorNotifier}
                />
              ) : (
                <>
                  <ConfirmContent handleClose={handleClose} />
                </>
              )}
            </Container>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
