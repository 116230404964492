import styled from "styled-components";

import { INDENT_BETWEEN_PAGES, PADDING } from "../../lib/constants";
import { colors } from "../../UIKit";

export const Container = styled.div`
  padding: ${INDENT_BETWEEN_PAGES}px;

  @media (max-width: 768px) {
    padding: 96px ${PADDING}px;
  }
`;

export const CardsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
  grid-gap: ${PADDING}px;
  padding-top: 80px;
`;

export const SliderWrapper = styled.div`
  padding-top: 64px;
`;

export const Card = styled.div`
  width: 384px;
  height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${PADDING}px;
  border-radius: 8px;
  border: 1px solid ${colors.gray[100]};

  @media (max-width: 768px) {
    width: 312px;
    height: 312px;
    padding: 20px 26px 20px 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 384px;
  height: 384px;
  align-items: center;
  gap: 8px;
  justify-content: center;
  cursor: pointer;
`;
