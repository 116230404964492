export const colors = {
  base: {
    white: "#FFFFFF",
    black: "#222228",
  },
  bg: "#F8F9FE",
  primary: {
    50: "#E4E4F2",
    100: "#CDCDE7",
    500: "#6258AC",
    700: "#534596",
  },
  gray: {
    100: "#D4D9E2",
    200: "#BABFCD",
    500: "#747F96",
    600: "#667085",
    700: "#545C6E",
  },
  opacity: {
    white: {
      60: "rgba(255, 255, 255, 0.6)", // 60%
    },
  },
};
