import styled from "styled-components";

import { INDENT_BETWEEN_PAGES, PADDING } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 ${INDENT_BETWEEN_PAGES}px;
  background-color: ${colors.bg};
`;

export const LeftContent = styled.div`
  max-width: 648px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Subtitle = styled.div`
  font-family: ${fontFamily.redHat.regular};
  font-size: 22px;
  letter-spacing: 0.22px;
  padding-bottom: ${PADDING}px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  gap: 16px;

  @media (max-width: 768px) {
    gap: 8px;
    padding-top: 32px;
    align-self: center;
  }
`;

export const RightContent = styled.div`
  position: relative;
`;

export const StyledGradient = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Mockups = styled.img`
  position: absolute;
  right: 130px;
  top: 100px;
`;

export const Gradient = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 400px;
  background: linear-gradient(
    135deg,
    #ffeba6 0%,
    #fffca5 16.15%,
    #ccffa5 31.25%,
    #a5ffc9 43.75%,
    #8bceff 56.25%,
    #dfc2ff 68.75%,
    #ffb4ea 83.85%,
    #ffa5c5 100%
  );
  filter: blur(60px);
  -webkit-filter: blur(60px);
`;
