import React, { useState } from "react";
import Slider from "react-slick";

import images from "../../assets/images";
import { WINDOW_WIDTH } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";
import { Text, Title, TitleBlock } from "../commonStyled";
import { adviceData } from "./data";
import {
  ExpectationItem,
  Container,
  ImageWrapper,
  ExpectationTitleWrapper,
  SliderWrapper,
  PagingContainer,
  PagingItemsWrapper,
  PagingItem,
} from "./mobileStyled";

export const MobileMethods = () => {
  return (
    <Container id="methods">
      <TitleBlock width={327}>
        <Text
          $ff={fontFamily.redHat.semiBold}
          $fsz={15}
          $lh={121}
          $ls={2.4}
          $tt="uppercase"
          color={colors.primary[500]}
        >
          Methods
        </Text>
        <Title $mt={12} $mb={40} $fs={WINDOW_WIDTH > 768 ? 32 : 24}>
          what you can expect
        </Title>
      </TitleBlock>
      <ImageWrapper>
        <img src={images.Method1} alt="Method.pic" />
      </ImageWrapper>
      <MethodsSlider />
    </Container>
  );
};

export const MethodsSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (currentSlide) => {
      setActiveSlide(currentSlide);
    },
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {adviceData.map((item) => (
          <ExpectationItem key={item.title}>
            <ExpectationTitleWrapper>
              {item.number}
              <Text
                $fsz={20}
                $align="left"
                $ff={fontFamily.redHat.bold}
                $lh={121}
                $ls={0.2}
              >
                {item.title}
              </Text>
            </ExpectationTitleWrapper>
            <Text
              $fsz={17}
              $align="left"
              $ff={fontFamily.redHat.regular}
              $lh={140}
              $ls={0.17}
            >
              {item.text}
            </Text>
          </ExpectationItem>
        ))}
      </Slider>
      <PagingContainer>
        <PagingItemsWrapper>
          {adviceData.map((_, index) => (
            <PagingItem key={index} $active={index === activeSlide} />
          ))}
        </PagingItemsWrapper>
      </PagingContainer>
    </SliderWrapper>
  );
};
