// import Review1 from "./review1.png";
import Review2 from "./review2.png";
import Review3 from "./review3.png";
import Review4 from "./review4.png";
import Review5 from "./review5.png";
import Review6 from "./review6.png";
import Review7 from "./review7.png";
import Review8 from "./review8.png";
import Review9 from "./review9.png";
import Review10 from "./review10.png";
import Review11 from "./review11.png";
import Review12 from "./review12.png";
import Review13 from "./review13.png";
import Review14 from "./review14.png";
import Review15 from "./review15.png";
import Review16 from "./review16.png";
import Review17 from "./review17.png";
import Review18 from "./review18.png";
import Review19 from "./review19.png";
import Review20 from "./review20.png";
import Review21 from "./review21.png";
import Review22 from "./review22.png";
import Review23 from "./review23.png";
import Review24 from "./review24.png";
import Review25 from "./review25.png";
import Review26 from "./review26.png";

const reviews = {
  // Review1,
  Review2,
  Review3,
  Review4,
  Review5,
  Review6,
  Review7,
  Review8,
  Review9,
  Review10,
  Review11,
  Review12,
  Review13,
  Review14,
  Review15,
  Review16,
  Review17,
  Review18,
  Review19,
  Review20,
  Review21,
  Review22,
  Review23,
  Review24,
  Review25,
  Review26,
};

export default reviews;
